import { getToken } from './auth/authService'

export function get(url) {
    let token = getToken()
    console.log("get", url);
    return fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
    })
}

export function post(url, body) {
    let token = getToken()
    console.log("POST", url, body)
    return fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    })
}


export function put(url, body) {
    let token = getToken()
    console.log("PUT", url, body)
    return fetch(url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(body),
    })
}
