import styled from 'styled-components'
import { White, Blue } from '../../../assets/colors'

export const CardDiv = styled.div`
    background: ${Blue};
    border-radius: 10px;
    display: flex;
    color: ${White};
    margin: 0 0 10px 0;
`

export const CenterItems = styled.div`
    padding: 20px 0px;
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Head = styled.div`
    border: 1px solid white;
    border-radius: 15px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-weight: bold;
    max-width: 400px;
    font-size: 20px;
`

export const Footer = styled.div`
    margin-top: 40px;
`

export const Logo = styled.div`
    /* padding-top: 20px; */
    padding-bottom: 20px;
`

export const Label = styled.div`
    font-size: 20px;
    font-weight: bolder;
`

export const LeftSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-size: 20px;
    font-weight: bolder;
`

export const RightSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-size: 20px;
    font-weight: bolder;
`

export const TextSection = styled.div`
    border-top:  ${props => (props.top ? 'none' : '1px solid white')};
    border-right: ${props => (props.right ? 'none' : '1px solid white')};
    border-left: ${props => (props.right ? '1px solid white' : 'none')};
    /* border-top-right-radius: ${props =>
        !props.lower ? (props.right ? '0px' : '10px') : '0px'};
    border-top-left-radius: ${props =>
        !props.lower ? (props.right ? '10px' : '0px') : '0px'}; */
    padding: 25px 20px;
`

export const LabelTag = styled.div`
    display: flex;
    justify-content: center;
`

export const NumberTag = styled.div`
    display: flex;
    justify-content: center;
`
