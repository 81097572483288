import React, { Component } from 'react';
import {
    CardDiv,
    CenterItems,
    Head,
    LeftSection,
    RightSection,
    Logo,
    LabelTag,
    NumberTag,
    TextSection,
} from './styled';
import { ReactComponent as Winner } from '../../../assets/images/goal.svg';
import CountUp from 'react-countup';

class StatusCard extends Component {

    render() {
        let { todayUserStats, contestUserStats } = this.props;
        return (
            <CardDiv>
                <LeftSection>
                    <TextSection top={true}>
                        <LabelTag>Score cumulé</LabelTag>
                        <NumberTag>
                            <CountUp
                                start={0}
                                end={Number(contestUserStats ? contestUserStats.globalscore : 0)}
                                duration={2}
                            />
                        </NumberTag>
                    </TextSection>
                    <TextSection lower={true}>
                        <LabelTag>Score Moyen</LabelTag>
                        <NumberTag>
                            <CountUp
                                start={0}
                                end={Number(contestUserStats ? contestUserStats.averageglobalscore : 0)}
                                duration={2}
                            />
                        </NumberTag>
                    </TextSection>
                </LeftSection>
                <CenterItems>
                    <Logo>
                        <Winner />
                    </Logo>
                    <Head> {'Stats de ' + (contestUserStats ? contestUserStats.publicname : "")}</Head>
                </CenterItems>
                <RightSection>
                    <TextSection right={true} top={true}>
                        <LabelTag>Mon Score</LabelTag>
                        <NumberTag>
                            <CountUp
                                start={0}
                                end={Number(todayUserStats ? todayUserStats.globalscore : 0)}
                                duration={2}
                            />
                        </NumberTag>
                    </TextSection>
                    <TextSection right={true} lower={true}>
                        <LabelTag>Mon Rang</LabelTag>
                        <NumberTag>
                            <CountUp
                                start={0}
                                end={Number(contestUserStats ? contestUserStats.rank : 0)}
                                duration={2}
                            />
                        </NumberTag>
                    </TextSection>
                </RightSection>
            </CardDiv>
        )
    }
}

export default StatusCard
