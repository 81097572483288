import styled from 'styled-components'

export const Box = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
`

export const Logo = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;
`

export const InputBox = styled.div``

export const ConnectDiv = styled.div`
    padding: 20px 0;
`
