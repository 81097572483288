import React, { Component } from 'react';
import {
	LeftTrophy,
	RightTrophy,
	CardDiv,
	CenterItems,
	Head,
	Table,
	Label,
} from './styled';
import { ReactComponent as Trophy } from '../../../assets/images/trophy.svg';
import CountUp from 'react-countup';
import Loader from '../../../components/Loader';
import { White, Red } from '../../../assets/colors';


import {
	Grid,
	List,
	ListItem,
	ListItemText,
	Divider,
} from '@material-ui/core';

class GeneralCard extends Component {

	listItemTextCountUp = (props) => {
		let { start, end, duration } = props;
		console.log("listItemTextCountUp", props);
		return (
			<div>
				{end}
			</div>
		)
	}

	render() {
		let { title, rank, loading } = this.props;
		return (
			<Grid container style={{background: Red, color: White, borderRadius: '10px', padding: '10px',}}>
					<Grid
						item
						xs={0} md={2}>
						<LeftTrophy>
							<Trophy />
						</LeftTrophy>
					</Grid>
					<Grid
						item
						xs={12} md={8}
					>
						<CenterItems>
							<Head> { title } </Head>
							<List style={{width: "100%",}}>
							{ loading ? (
								<Loader visibility={loading} color={White} />
							) : (
								rank.map(item => (
									<div>
									<ListItem >
										<Grid container >
											<Grid item xs={8} md={4}>
												{item.publicname}
											</Grid>
											<Grid container xs={2} md={4} justify="center">
												<CountUp
												start={0}
												end={Number(item.globalscore)}
												duration={2}
											/>
											</Grid>
											<Grid container xs={2} md={4} justify="flex-end">
												{item.rank + (item.rank === 1 ? 'er' : 'eme')}
											</Grid>
										</Grid>
									</ListItem>
									<Divider />
									</div>
								))
							)}
							</List>
						</CenterItems>
					</Grid>
					<Grid 
						item
						xs={0} md={2}
						style={{
							flex: 1,
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
						}}>
						<RightTrophy>
							<Trophy />
						</RightTrophy>
					</Grid>
			</Grid>
		)
	}
}

export default GeneralCard
