import React, { Component } from 'react'
import { Box, Logo, InputBox, ConnectDiv } from './styled'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { Red, White } from '../../../assets/colors'
import LoaderClass from '../../../components/Loader'
import Api from '../../../core/api'
import * as Services from '../../../core/auth/authService'
import ValidationMessage from '../../../components/ValidationMessage'
import { withSnackbar } from 'notistack';


import { ReactSVG } from 'react-svg'


class ForgotPassword extends Component {
    state = {
        loading: false,
        email: '', 
        emailValid: false,
        formValid: false,
        errorMsg: {}
    }

    componentDidMount() {
        this.fetchData()
    }

    handleKeyPress = () => {
        // eslint-disable-next-line
        if (window.event.keyCode == 13) {
            // this.handleLogin()
        }
    }

    fetchData = async () => {
        await Api.groupLogo(this.state.groupid).then(data => {
            if (data && data.data && !data.data.error) {
                console.log(data.data.url);
                this.setState({
                    logo: data.data.url,
                })
            }
        })
    }

    handleSubmit = () => {
        Services.requestPasswordResetLink(this.state.email).then((resp) => {
            console.log(resp);
            this.props.enqueueSnackbar('Un lien de réinitialisation va vous être envoyé par mail', { variant: 'success'});

        })
    }

    validateForm = () => {
        const { emailValid} = this.state;
        this.setState({
            formValid: emailValid,
        })
    }

    updateEmail = (email) => {
        this.setState({email}, this.validateEmail);
    }

    validateEmail = () => {
        const {email} = this.state;
        let emailValid = true;
        let errorMsg = {...this.state.errorMsg};

        // checks for format _@_._
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
            emailValid = false;
            errorMsg.email = 'Invalid email format';
        }

        this.setState({emailValid, errorMsg}, this.validateForm);
    }

    render() {
        return (
            <Box>
                <Logo>
                    <ReactSVG
                        src={this.state.logo}
                        wrapper="span"
                        className="wrapper-class-name"
                    />
                </Logo>
                <InputBox>
                    <Input
                        width={'250px'}
                        border={'none'}
                        outline={'none'}
                        radius={'50px'}
                        padding={'20px 30px'}
                        size={'20px'}
                        placeholder={'Email'}
                        onKeyPress={this.handleKeyPress}
                        onChange={(e) => this.updateEmail(e.target.value)}
                    />
                    <ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
                </InputBox>
                <ConnectDiv>
                    <Button
                        onClick={this.handleSubmit}
                        backgroundColor={Red}
                        textColor={White}
                        fontSize={20}
                        bold={true}
                        padding={'15px 30px'}
                        borderRadius={30}
                        borderColor={White}
                        disabled={!this.state.formValid}
                    >
                        Envoyer un lien de récupération
                    </Button>
                </ConnectDiv>
                <Button
                        width={'400px'}
                        onClick={()=>{document.location.assign('/auth/login')}}
                        backgroundColor={Red}
                        textColor={White}
                        fontSize={20}
                        bold={true}
                        padding={'15px 30px'}
                        borderRadius={30}
                        borderColor={White}
                    >
                        &lt; Retour à la page de connexion
                </Button>
                <LoaderClass visibility={this.state.loading} color={White} />
            </Box>
        )
    }
}

export default withSnackbar(ForgotPassword);
