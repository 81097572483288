import React, { Component } from 'react'
import {
    Main,
    Header,
    HeadLabel,
    Content,
    ContentLabel,
    ExtraDiv,
} from './styled'
import { ReactComponent as Winner } from '../../assets/images/crown.svg'

export class Table extends Component {
    render() {
        let { columns, data } = this.props;
        let { points } = this.props;

        return (
            <Main background={this.props.background}>
                <Header
                    headRadius={this.props.headRadius}
                    border={this.props.headBorder}
                    background={this.props.headColor}
                    color={this.props.headText}
                >
                    <HeadLabel justify={'flex-start'}>{columns[0]}</HeadLabel>
                    <HeadLabel justify={'center'}>{columns[1]}</HeadLabel>
                    <HeadLabel justify={'flex-end'}>{columns[2]}</HeadLabel>
                </Header>
                {data &&
                    data.map((item, index) => (
                        <Content key={index}>
                            <ContentLabel
                                rank={item.rank}
                                justify={'flex-start'}
                            >
                                {this.props.general && (
                                    <ExtraDiv>
                                        {item.rank === 1 && <Winner />}
                                    </ExtraDiv>
                                )}

                                {item.publicname}
                            </ContentLabel>
                            <ContentLabel flex={2} justify={'center'}>
                                {item[points].toFixed(0)}
                            </ContentLabel>
                            <ContentLabel justify={'flex-end'}>
                                {item.rank ? item.rank : "N/A"}
                            </ContentLabel>
                        </Content>
                    ))}
            </Main>
        )
    }
}

export default Table
