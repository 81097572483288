import styled from 'styled-components'

export const Main = styled.div`
    background: ${props => props.background && props.background};
    border-radius: 10px;
`

export const Header = styled.div`
    display: flex;
    border-bottom: ${props => props.border && '1px solid lightgrey'};
    height: 40px;
    background: ${props => props.background};
    color: ${props => props.color};
    padding: 0 20px;
    border-radius: ${props => props.headRadius && '10px'};
`

export const HeadLabel = styled.div`
    flex: 1;
    display: flex;
    justify-content: ${props => props.justify};
    align-items: center;
    font-size: 25px;
    font-weight: bold;
`

export const Content = styled.div`
    padding: 15px 20px;
    display: flex;
    height: 30px;
`

export const ExtraDiv = styled.div`
    width: 50px;
`

export const ContentLabel = styled.div`
    flex: 1;
    display: flex;
    font-size: 30px;
    justify-content: ${props => props.justify};
    align-items: center;
`
