import styled from 'styled-components'
import { Red, White } from '../../../assets/colors'

export const Box = styled.div`
	/*flex: 1;*/
	background: ${Red};
	/*display: flex;*/
	/* max-width: 1600px; */
`

export const Main = styled.div`
	flex: 1;
`

export const Perco = styled.div`
	display: flex;
	align-self: flex-end;
`

export const Logo = styled.div`
	display: flex;
	justify-content: center;
	padding: 30px 0;
`

export const Body = styled.div`
	display: flex;
	padding: 0 20px 0px 20px;
`

export const Left = styled.div`
	flex: 1;
	margin-bottom: 20px;
`

export const VerticalLine = styled.div`
	border: 1px solid white;
	margin-top: 30px;
`

export const Right = styled.div`
	flex: 1;
	margin-bottom: 20px;
`

export const MinHead = styled.div`
	text-align: center;
	color: white;
	font-size: 22px;
	font-weight: bold;
`

export const InformationDiv = styled.div`
	padding: 20px 50px;
`

export const Label = styled.div`
	font-size: 22px;
	color: ${White};
	padding-bottom: 10px;
`

export const ItemDiv = styled.div`
	padding: 5px 0px;
	margin: 0px 10px;
`

export const MultipleButtons = styled.div`
	display: flex;
`

export const ButtonDiv = styled.div`
	padding-right: 20px;
`

export const DropDownBox = styled.div`
	display: flex;
	padding: 10px 0;
`

export const DropDownLabel = styled.div`
	font-size: 25px;
	color: ${White};
	padding-right: 120px;
`

export const DropDownMenu = styled.div``

export const SubmitDiv = styled.div`
	margin: 15px 0;
	text-align: center;
`
