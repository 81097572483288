import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Box = styled.div`
    margin: 0.5% 2% 1% 2%;
    flex: 1;
    max-width: 1356px;
`

export const Content = styled.div`
    background: ${White};
    padding: 10px 0;
    border-radius: 10px;
    font-size: 25px;
    font-weight: bold;
`