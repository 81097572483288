import React, { Component } from 'react'
import {
    Main,
    Head,
    Topleft,
    TopRight,
    Text,
    Body,
    BodyLeft,
    BodyMiddle,
    BodyRight,
    BodyText,
    BodyNumber,
} from './styled'
import { ReactComponent as Inactive } from '../../assets/images/ic_journee_inactivite.svg'
import { ReactComponent as Alerts } from '../../assets/images/ic_journee_alerte.svg'

export default class Card extends Component {
    IconRender = card => {
        if (card === 'inactive') {
            return <Inactive />
        } else {
            return <Alerts />
        }
    }

    render() {
        let {
            color1,
            color2,
            value1,
            value2,
            textLeft,
            textRight,
            title,
            card,
        } = this.props
        const Icon = () => this.IconRender(card)
        return (
            <Main color1={color1} color2={color2}>
                <Head>
                    <Topleft>
                        <Icon />
                    </Topleft>
                    <TopRight>
                        <Text radius={'20px'}>{title}</Text>
                    </TopRight>
                </Head>
                <Body>
                    <BodyLeft>
                        <BodyText>{textLeft}</BodyText>
                        <BodyNumber>{value1}</BodyNumber>
                    </BodyLeft>
                    <BodyMiddle />
                    <BodyRight>
                        <BodyText>{textRight}</BodyText>
                        <BodyNumber>{value2}</BodyNumber>
                    </BodyRight>
                </Body>
            </Main>
        )
    }
}
