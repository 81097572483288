import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Box = styled.div`
    display: flex;
    background: ${props =>
        props.color1 &&
        props.color2 &&
        'linear-gradient(to bottom right, ' +
            props.color1 +
            ' 0%,' +
            props.color2 +
            ' 100%)'};
    border-radius: 15px;
    min-height: 120px;
`
export const Left = styled.div`
    flex: 1;
    /* display: flex;
    align-items: center; */
    padding: 20px 0 0 20px;
`

export const Right = styled.div`
    flex: 2;
    display: flex;
    color: ${White};
    font-size: 30px;
    font-weight: bold;
    justify-content: flex-start;
    align-items: center;
`
