import React, { Component } from 'react';
import {
    Box,
    JourneyHead,
    JourneyContent,
    JourneyLeft,
    JourneyRight,
    JourneyRightUp,
    JourneyRightDown,
    AreaChart,
    PieDiv,
    PostureRecap,
    AreaRecapDiv,
    ProgressHead,
    StatusLineDiv,
    LineDiv,
    StatusTimeDiv,
    BulletDiv,
    LabelDiv,
    TimeDiv,
    FlexonDiv,
    InclineDiv,
    RightUpHead,
    RightUpImage,
    RightUpStatusLine,
    RightUpTimeDiv,
    ScoreHead,
    ScoreBody,
    ScoreCalculation,
    EqualToDiv,
    TotalScore,
    ScoreUp,
    Summary,
    SummaryLeft,
    SummaryIcon,
    SummaryTag,
    SummaryRight,
    PostureScore,
    ScoreDown,
    Calculate,
    Operator,
    IconTag,
    VerticalLine,
    ScorePicsDiv,
    NumberFont,
    ProgressionHead,
    ProgressionContent,
    // ProgressionLeft,
    // ProgressionRight,
    // ProgressionPoints,
    // ProgressionMessage,
    IconNumber,
} from './styled';
import ProgressPie from '../../components/ProgressPie';
import StatusArea from '../../components/StatusArea';
import ProgressionSection from '../../components/ProgressionSection';
// import StatusBar from '../../components/StatusBar';
import { ProgressLine } from '../../components/ProgressLine';
import { Blue, AppBack, Red, White } from '../../assets/colors';
import { Bullet } from '../../components/Bullets';
import { PointsCircle } from '../../components/PointsCircle';
import Flexon from '../../assets/images/pic_avachissement_back.png';
import Incline from '../../assets/images/pic_inclinaison_right.png';
import { ReactComponent as Inactive } from '../../assets/images/inactivite_blue.svg';
import { ReactComponent as Exercise } from '../../assets/images/yellow_exercise.svg';
import { ReactComponent as Alerts } from '../../assets/images/alerte_red.svg';
import * as Services from '../../core/auth/authService';
import Api from '../../core/api';
import moment from 'moment';
import CountUp from 'react-countup';
import Loader from '../../components/Loader';


export default class Status extends Component {
    componentDidMount() {
        this.checkToken()
    }
    state = {
        userStats: null,
        groupStats: null,
        singleUserStats: null,
        totalScore: null,
        singleUserDaily: [],
        userStatsLoading: false,
        chartData: {},
        contestStartDate: new Date(),
        contestEndDate: new Date(),
    }

    checkToken = async () => {
        let token = Services.getToken()
        if (!token) {
            this.props.history.push('/auth/login')
        }

        this.setState({ userStatsLoading: true })

        await Api.getUserHistory(
            moment().weekday(),
            moment().week(),
            moment().year()
        ).then(data => {
            if (data && !data.error) {
                this.setState({
                    userStats: data.data,
                })
            }
            this.setState({ userStatsLoading: false })
        })
        let startDate =moment().utc().startOf('day').toISOString();
        let endDate = moment().utc().endOf('day').toISOString();

        let startWeek =moment().utc().startOf('week').toISOString();
        let endWeek = moment().utc().endOf('week').toISOString();

        let weekDay = moment().weekday();
        let week = moment().week();
        let year = moment().year();

        // let startDate = '2020-02-25T00:00:00Z'
        // let endDate = '2020-02-25T23:59:59.999999999Z'
        // let startWeek = '2020-02-16T00:00:00Z'
        // let endWeek = '2020-02-23T23:59:59.999999999Z'

        await Api.groupHistory(startDate, endDate).then(info => {
            console.log(info);
            if (info && !info.error) {
                console.log("groupHistory", info.data); 
                this.setState({
                    groupStats: info.data,
                })
            }
        })

        await Api.groupHistoryWeek(startWeek, endWeek).then(data => {
            if (data && !data.error) {
                this.setState({
                    totalScore: data.data[0] ? data.data[0].globalscore : 0,
                })
            }
        })

        await Api.getUserHistory(weekDay, week, year).then(user => {
            if (user && !user.error) {
                this.setState({
                    userStats: user.data,
                })
            }
        })

         await Api.groupContestDates().then(resp =>{
            console.log("groupContestDates", resp);
            if (resp && !resp.error) {
                let contestStartDate = moment(resp.data.StartDate).utc().startOf('day').toISOString();
                let contestEndDate = moment(resp.data.EndDate).utc().startOf('day').toISOString();

                //endDate = moment(endDate).isBefore(contestEndDate) ? endDate : contestEndDate;

                Api.singleUserDaily(contestStartDate, contestEndDate).then(resp => {
                    if (resp && resp.data && resp.data.length > 0 && !resp.error) {
                        console.log("singleUserDaily", resp.data);
                        this.setState({
                            singleUserDaily: resp.data,
                        })
                    }
                });

                this.setState({
                    contestStartDate: contestStartDate,
                    contestEndDate: contestEndDate,
                })
               }
           });

    }

    render() {
        let { userStats, groupStats, singleUserDaily } = this.state;
        let { contestStartDate, contestEndDate } = this.state;
        return (
            <Box>
                <JourneyHead>Votre journée</JourneyHead>
                <JourneyContent>
                    <JourneyLeft>
                        <AreaRecapDiv>
                            <AreaChart>
                                {this.state.userStatsLoading ? (
                                    <Loader
                                        visibility={this.state.userStatsLoading}
                                        color={Blue}
                                    />
                                ) : (
                                    userStats && (
                                        <StatusArea
                                            stats={
                                                userStats &&
                                                userStats.normalizedposturepointshistory
                                            }
                                            gestureData={
                                                 userStats &&
                                                 userStats.riskymovementhistory
                                            }
                                            starttime={userStats.starttime}
                                            endtime={userStats.endtime}
                                            maxPoints={120}
                                        />
                                    )
                                )}
                            </AreaChart>
                            <PostureRecap>
                                <ProgressHead>Mon recap posture</ProgressHead>
                                <LineDiv>
                                    <StatusLineDiv
                                        flex={
                                            userStats && userStats.postureratio
                                                ? userStats.postureratio.good /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine color={Blue} />
                                    </StatusLineDiv>
                                    <StatusLineDiv
                                        flex={
                                            userStats && userStats.postureratio
                                                ? userStats.postureratio.bad /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine
                                            shadow={1}
                                            color={AppBack}
                                        />
                                    </StatusLineDiv>
                                </LineDiv>

                                <StatusTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={Blue} size={8} />
                                    </BulletDiv>

                                    <LabelDiv minWidth={150}>
                                        Bon pour mon dos
                                    </LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.postureratio &&
                                                    (
                                                        (userStats.postureratio
                                                            .good *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.postureratio &&
                                                    (
                                                        (userStats.postureratio
                                                            .good *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </StatusTimeDiv>
                                <StatusTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={AppBack} size={8} />
                                    </BulletDiv>
                                    <LabelDiv minWidth={150}>
                                        Peut mieux faire
                                    </LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.postureratio &&
                                                    (
                                                        (userStats.postureratio
                                                            .bad *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.postureratio &&
                                                    (
                                                        (userStats.postureratio
                                                            .bad *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </StatusTimeDiv>
                            </PostureRecap>
                            <PieDiv>
                        </PieDiv>
                        </AreaRecapDiv>

                        
                    </JourneyLeft>
                    <JourneyRight>
                        <JourneyRightUp>
                            <FlexonDiv>
                                <RightUpHead>FLEXION</RightUpHead>
                                <RightUpImage>
                                    <img src={Flexon} alt={''} />
                                </RightUpImage>
                                <RightUpStatusLine>
                                    <StatusLineDiv
                                        flex={
                                            userStats && userStats.flexionratio
                                                ? userStats.flexionratio
                                                      .balanced /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine color={Blue} />
                                    </StatusLineDiv>
                                    <StatusLineDiv
                                        flex={
                                            userStats && userStats.flexionratio
                                                ? userStats.flexionratio
                                                      .sloutched /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine color={AppBack} />
                                    </StatusLineDiv>
                                </RightUpStatusLine>
                                <RightUpTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={Blue} size={8} />
                                    </BulletDiv>

                                    <LabelDiv minWidth={100}>
                                        Equilibré
                                    </LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.flexionratio &&
                                                    (
                                                        (userStats.flexionratio
                                                            .balanced *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.flexionratio &&
                                                    (
                                                        (userStats.flexionratio
                                                            .balanced *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </RightUpTimeDiv>
                                <RightUpTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={AppBack} size={8} />
                                    </BulletDiv>

                                    <LabelDiv minWidth={100}>Avachi</LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.flexionratio &&
                                                    (
                                                        (userStats.flexionratio
                                                            .sloutched *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.flexionratio &&
                                                    (
                                                        (userStats.flexionratio
                                                            .sloutched *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </RightUpTimeDiv>
                            </FlexonDiv>
                            <InclineDiv>
                                <RightUpHead end={1}>INCLINAISON</RightUpHead>
                                <RightUpImage>
                                    <img src={Incline} alt={''} />
                                </RightUpImage>
                                <RightUpStatusLine>
                                    <StatusLineDiv
                                        flex={
                                            userStats &&
                                            userStats.inclinaisonratio
                                                ? userStats.inclinaisonratio
                                                      .left /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine color={AppBack} />
                                    </StatusLineDiv>
                                    <StatusLineDiv
                                        flex={
                                            userStats &&
                                            userStats.inclinaisonratio
                                                ? userStats.inclinaisonratio
                                                      .balanced /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine color={Blue} />
                                    </StatusLineDiv>
                                    <StatusLineDiv
                                        flex={
                                            userStats &&
                                            userStats.inclinaisonratio
                                                ? userStats.inclinaisonratio
                                                      .right /
                                                  userStats.totalusetime
                                                : 0
                                        }
                                    >
                                        <ProgressLine color={AppBack} />
                                    </StatusLineDiv>
                                </RightUpStatusLine>
                                <RightUpTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={AppBack} size={8} />
                                    </BulletDiv>
                                    <LabelDiv minWidth={150}>
                                        Inclinaison Gauche
                                    </LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.inclinaisonratio &&
                                                    (
                                                        (userStats
                                                            .inclinaisonratio
                                                            .left *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.inclinaisonratio &&
                                                    (
                                                        (userStats
                                                            .inclinaisonratio
                                                            .left *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </RightUpTimeDiv>
                                <RightUpTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={Blue} size={8} />
                                    </BulletDiv>
                                    <LabelDiv minWidth={150}>
                                        Equilibré
                                    </LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.inclinaisonratio &&
                                                    (
                                                        (userStats
                                                            .inclinaisonratio
                                                            .balanced *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.inclinaisonratio &&
                                                    (
                                                        (userStats
                                                            .inclinaisonratio
                                                            .balanced *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </RightUpTimeDiv>
                                <RightUpTimeDiv>
                                    <BulletDiv>
                                        <Bullet color={AppBack} size={8} />
                                    </BulletDiv>

                                    <LabelDiv minWidth={150}>
                                        Inclinaison Droite
                                    </LabelDiv>
                                    <TimeDiv>
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.inclinaisonratio &&
                                                    (
                                                        (userStats
                                                            .inclinaisonratio
                                                            .right *
                                                            0.0166) /
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            suffix={'h'}
                                            duration={2}
                                        />
                                        <CountUp
                                            start={0}
                                            end={Number(
                                                userStats &&
                                                    userStats.inclinaisonratio &&
                                                    (
                                                        (userStats
                                                            .inclinaisonratio
                                                            .right *
                                                            0.0166) %
                                                        60
                                                    ).toFixed(0)
                                            )}
                                            duration={2}
                                        />
                                    </TimeDiv>
                                </RightUpTimeDiv>
                            </InclineDiv>
                        </JourneyRightUp>
                        <JourneyRightDown>
                            <ScoreHead>
                                Mon calcul de score journalier
                            </ScoreHead>
                            <ScoreBody>
                                <ScoreCalculation>
                                    <ScoreUp>
                                        <Summary>
                                            <SummaryLeft>
                                                <PostureScore>
                                                    <ProgressPie
                                                        size={80}
                                                        font={25}
                                                        stats={Number(
                                                            groupStats &&
                                                                groupStats.posturegrade &&
                                                                (
                                                                    groupStats.posturegrade *
                                                                    100
                                                                ).toFixed(0)
                                                        )}
                                                    />
                                                </PostureScore>
                                                
                                            </SummaryLeft>
                                            <SummaryRight>
                                                <IconTag>
                                                    <SummaryIcon>
                                                        <Inactive />
                                                        <IconNumber>
                                                            <CountUp
                                                                start={0}
                                                                end={Number(
                                                                    groupStats &&
                                                                        groupStats.inactivitycount
                                                                        ? groupStats.inactivitycount.toFixed(0)
                                                                        : 0
                                                                )}
                                                                duration={2}
                                                            />
                                                        </IconNumber>
                                                    </SummaryIcon>
                                                    <SummaryTag>
                                                        inactivité
                                                    </SummaryTag>
                                                </IconTag>
                                                <VerticalLine />
                                                <IconTag>
                                                    <SummaryIcon>
                                                        <Alerts />
                                                        <IconNumber>
                                                            <CountUp
                                                                start={0}
                                                                end={Number(
                                                                    groupStats &&
                                                                        groupStats.riskygesturecount
                                                                        ? groupStats.riskygesturecount.toFixed(0)
                                                                        : 0
                                                                )}
                                                                duration={2}
                                                            />
                                                        </IconNumber>
                                                    </SummaryIcon>
                                                    <SummaryTag>
                                                        Alertes
                                                    </SummaryTag>
                                                </IconTag>
                                                <VerticalLine />

                                                <IconTag>
                                                    <SummaryIcon>
                                                        <Exercise />
                                                        <IconNumber>
                                                            <CountUp
                                                                start={0}
                                                                end={Number(
                                                                    groupStats &&
                                                                        groupStats.exercisecount
                                                                        ? groupStats.exercisecount.toFixed(0)
                                                                        : 0
                                                                )}
                                                                duration={2}
                                                            />
                                                        </IconNumber>
                                                    </SummaryIcon>
                                                    <SummaryTag>
                                                        Exercise
                                                    </SummaryTag>
                                                </IconTag>
                                            </SummaryRight>
                                        </Summary>
                                    </ScoreUp>

                                    <ScoreDown>
                                        <ScorePicsDiv>
                                            <Calculate>
                                                <NumberFont>
                                                    <CountUp
                                                        start={0}
                                                        end={Number(
                                                            groupStats &&
                                                                groupStats.posturescore
                                                                ? Math.ceil(groupStats.posturescore)
                                                                : 0
                                                        )}
                                                        duration={2}
                                                    />
                                                </NumberFont>
                                                pts
                                            </Calculate>
                                             <Operator>-</Operator>
                                        </ScorePicsDiv>
                                        <ScorePicsDiv>
                                            <Calculate>
                                                <NumberFont>
                                                    <CountUp
                                                        start={0}
                                                        end={Number(
                                                            groupStats &&
                                                                groupStats.inactivityscore
                                                                ? Math.abs(groupStats.inactivityscore.toFixed(0))
                                                                : 0
                                                        )}
                                                        duration={2}
                                                    />
                                                </NumberFont>
                                                pts
                                            </Calculate>
                                            <Operator>-</Operator>
                                        </ScorePicsDiv>
                                        <ScorePicsDiv>
                                            <Calculate>
                                                <NumberFont>
                                                    <CountUp
                                                        start={0}
                                                        end={Number(
                                                            groupStats &&
                                                                groupStats.riskygesturescore
                                                                ? Math.abs(groupStats.riskygesturescore.toFixed(0))
                                                                : 0
                                                        )}
                                                        duration={2}
                                                    />
                                                </NumberFont>
                                                pts
                                            </Calculate>
                                            <Operator>+</Operator>
                                        </ScorePicsDiv>
                                        <ScorePicsDiv>
                                            <Calculate>
                                                <NumberFont>
                                                    <CountUp
                                                        start={0}
                                                        end={Number(
                                                            groupStats &&
                                                                groupStats.exercisescore
                                                                ? Math.abs(groupStats.exercisescore.toFixed(0))
                                                                : 0
                                                        )}
                                                        duration={2}
                                                    />
                                                </NumberFont>
                                                pts
                                            </Calculate>
                                        </ScorePicsDiv>
                                        
                                    </ScoreDown>
                                </ScoreCalculation>
                                <EqualToDiv> = </EqualToDiv>
                                <TotalScore>
                                    <PointsCircle
                                        small={1}
                                        font={25}
                                        size={100}
                                        background={Red}
                                        color={White}
                                        score={
                                            groupStats && groupStats.globalscore
                                                ? Math.ceil(groupStats.globalscore)
                                                : 0
                                        }
                                    />
                                </TotalScore>
                            </ScoreBody>
                        </JourneyRightDown>
                    </JourneyRight>
                </JourneyContent>
                <ProgressionHead>Ma Progression</ProgressionHead>
                <ProgressionContent>
                    <ProgressionSection
                        dailyStats={singleUserDaily}
                        starttime={contestStartDate}
                        endtime={contestEndDate}
                    />
                </ProgressionContent>
            </Box>
        )
    }
}
