import React, { Component } from 'react'
import { ValidationDiv } from './styled'

export class ValidationMessage extends Component {

	render() {
		if (!this.props.valid) {
			return(
				<ValidationDiv className='error-msg' >{this.props.message}</ValidationDiv>
			)
		}
		return null;
	}
}

export default ValidationMessage
