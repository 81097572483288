import React, { Component } from 'react'
import { Main, HeadDiv, NumberTag, Text } from './styled'

export default class Card extends Component {
    render() {
        let { text1, text2 } = this.props
        let { value1, value2 } = this.props
        let { units1, units2 } = this.props
        let { color1, color2 } = this.props

        return (
            <Main color1={color1} color2={color2}>
                <HeadDiv>
                    <Text border={1} radius={'20px'}>
                        {text1}
                    </Text>
                </HeadDiv>

                <NumberTag>
                    <Text>{value1 + ' ' + units1}</Text>
                </NumberTag>
                <HeadDiv>
                    <Text border={1} radius={'20px'}>
                        {text2}
                    </Text>
                </HeadDiv>

                <NumberTag>
                    <Text>{value2 + ' ' + units2}</Text>
                </NumberTag>
            </Main>
        )
    }
}
