import React, { Component } from 'react'
import { Box } from './styled'
import { VictoryPie } from 'victory'
import { Blue, Black, AppBack } from '../../assets/colors'
import CountUp from 'react-countup'

class ProgressPie extends Component {
    render() {
        return (
            <Box size={this.props.size}>
                <div
                    style={{
                        position: 'absolute',
                        left: (this.props.size - 30) / 2,
                        top: (this.props.size - this.props.font) / 2,
                        width: 30,
                        display: 'flex',
                        justifyContent: 'center',
                        height: this.props.font,
                        fontSize: this.props.font,
                        color: Black,
                    }}
                >
                    <CountUp
                        start={0}
                        end={Number(this.props.stats ? this.props.stats : 0)}
                        duration={3}
                        suffix="%"
                    />
                </div>
                <VictoryPie
                    // origin={{ x: this.props.size, y: this.props.size }}
                    animate={{
                        duration: 1500,
                    }}
                    height={this.props.size / 2}
                    width={this.props.size / 2}
                    radius={this.props.size / 4}
                    innerRadius={(this.props.size - 15) / 4}
                    labels={() => null}
                    data={[
                        { y: this.props.stats ? Number(this.props.stats) : 0 },
                        {
                            y: this.props.stats
                                ? 100 - Number(this.props.stats)
                                : 100,
                        },
                    ]}
                    colorScale={[Blue, AppBack]}
                />
            </Box>
        )
    }
}

export default ProgressPie
