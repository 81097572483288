import React, { Component } from 'react';

import { Oulined, Title, Content, TitleContainer } from './styled';


class OutlinedSection extends Component {

	render() {
		let props = this.props;

		return (
			<Oulined {...props}>
				<TitleContainer>
					<Title {...props}>
						{props.title}
					</Title>
				</TitleContainer>
				<Content>
					{props.children}
				</Content>
			</Oulined>
		);
	}
}

export default OutlinedSection;