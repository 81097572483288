import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Div = styled.div`
    height: auto;
    width: 100%;
    background: ${White};
    bottom: 0;
    left: 0;
    display: flex;
`

export const CopyRight = styled.div`
    padding: 10px 20px;
    flex: 1;
    font-size: 20px;
    box-sizing: border-box;
    font-weight: lighter;
`

export const Contact = styled.div`
    display: flex;
    font-size: 20px;
    padding: 10px 20px;
    justify-content: flex-end;
`
