import React, { Component } from 'react';

import {
	VictoryChart,
	VictoryAxis,
	VictoryLabel,
	VictoryLine,
	VictoryVoronoiContainer,
	VictoryTooltip,
	VictoryLegend,
} from 'victory';
import moment from 'moment';

import {
	AreaChartColor3,
	Blue,
	Red,
	YellowCardColor1,
	InactivityColor,
} from '../../assets/colors';

import { Container } from './styled';
import Loader from '../../components/Loader';

import { ReactComponent as Inactive } from '../../assets/images/inactivite_blue.svg';
import { ReactComponent as Exercise } from '../../assets/images/yellow_exercise.svg';
import { ReactComponent as Alerts } from '../../assets/images/alerte_red.svg';

import styled from "styled-components";

class CustomLegend extends Component {
	render()
	{
		const {x, y, datum} = this.props;
		let symbol = <Alerts x={x} y={y} width="20px" size={this.props.size}/>;
		console.log(datum);
		switch(datum.name)
		{
			case 'Posture':
				symbol = <Alerts x={x} y={y} width="20px" size={this.props.size}/>
			break;
			case 'inactivitées':
				symbol = <Inactive x={x} y={y} width="20px" size={this.props.size}/>
			break;
			case 'Exercices':
				symbol = <Exercise x={x} y={y} width="20px" size={this.props.size}/>
			break;
			case 'Gestes à risque':
				symbol = <Alerts x={x} y={y} width="20px" size={this.props.size}/>
			break;
			default:
			break;
		}

		return symbol;
	}
}


const TooltipContainer = styled("div")`
  color: #868c97;
  margin: 0;
  padding: 0;
  font-size: 10px;
  height: 100%;
  width: 100%;
`;

const TooltipDataContainer = styled("div")`
  border: 1px solid #868c97;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 1em 0.5em 0.5em 1em;
`;

const Name = styled("div")`
  padding-bottom: 0.2em;
`;

const Score = styled("div")`
  padding-bottom: 0.2em;
`;


class PostureGraphTooltip extends React.Component {

	render () {
		const { datum, x, y } = this.props;
		console.log("datum", datum);
		return (
			<g style={{pointerEvents: 'none'}}>
				<foreignObject x={x - 40} y={y - 50} width="90" height="50">
				<TooltipContainer xmlns="http://www.w3.org/1999/xhtml">
				<TooltipDataContainer>
					<Name>{`Name: ${datum.name}`}</Name>
					<Score>{`Points: ${datum.score}`}</Score>
				</TooltipDataContainer>
				</TooltipContainer>
				</foreignObject>
			</g>
		);
	}
}


class ProgressionSection extends Component {

	constructor(props) {
		super(props)
		let starttime = new Date(props.starttime)
		let endtime = new Date(props.endtime)

		this.state = {
			dailyStats: props.dailyStats,
			postureScore: undefined,
			exercicesCount: undefined,
			inactivityCount: undefined,
			riskyGestureCount: undefined,
			dataLoaded: false,
			starttime: starttime,
			endtime: endtime,
			domain: { x: [new Date(starttime), new Date(endtime)], y: [0, 85] },
		};
	}

	componentDidMount() {
		this.fillData()
	}

	componentDidUpdate(prevProps) {
		const { dailyStats, starttime, endtime } = this.props
		//console.log("starttime", starttime,"endtime", endtime);
		if (prevProps.dailyStats.length !== dailyStats.length) {
			this.fillData();
		}
		if (prevProps.starttime !== starttime) {
			this.setState({
				starttime: new Date(starttime),
				domain: { x: [new Date(starttime), new Date(endtime)], y: [0, 85] },
			});
			this.fillData();
		}
		if (prevProps.endtime !== endtime) {
			this.setState({
				endtime: new Date(endtime),
				domain: { x: [new Date(starttime), new Date(endtime)], y: [0, 85] },
			});
			this.fillData();
		}
	}

	fillData = () => {
		let { postureScore, exercicesCount, inactivityCount, riskyGestureCount } = this.state;
		let { starttime, endtime } = this.state;
		let { dataLoaded } = this.state;
		let { dailyStats } = this.props;

		console.log("dailyStats", dailyStats);
		console.log("starttime", starttime,"endtime", endtime);

		if (dailyStats.length) {
			postureScore = [];
			exercicesCount = [];
			inactivityCount = [];
			riskyGestureCount = [];
			
			let date = moment(this.state.starttime);
			let dailyStatsIndex = 0;

			for (let i=0; i < moment(this.state.endtime).diff(this.state.starttime, 'days'); i++)
			{
				if (dailyStatsIndex < dailyStats.length) {
					//console.log(moment(dailyStats[dailyStatsIndex].starttime).startOf('day'), date.startOf('day'), moment(dailyStats[dailyStatsIndex].starttime).isSame(date, 'day'));

					//console.log("dailyStats[dailyStatsIndex]", dailyStats[dailyStatsIndex].starttime, "date", date , moment(dailyStats[dailyStatsIndex].starttime).isSame(date, 'day'))
					if (moment(dailyStats[dailyStatsIndex].starttime).isSame(date, 'day'))
					{
						postureScore.push({ x: new Date(date), y: dailyStats[dailyStatsIndex].posturescore/*, label: dailyStats[dailyStatsIndex].posturescore.toFixed(0)*/});
						exercicesCount.push({ x: new Date(date), y: dailyStats[dailyStatsIndex].exercisecount/*, label: dailyStats[dailyStatsIndex].exercisecount.toFixed(0)*/});
						inactivityCount.push({ x: new Date(date), y: dailyStats[dailyStatsIndex].inactivitycount/*, label: dailyStats[dailyStatsIndex].inactivitycount.toFixed(0)*/});
						riskyGestureCount.push({ x: new Date(date), y: dailyStats[dailyStatsIndex].riskygesturecount/*, label: dailyStats[dailyStatsIndex].riskygesturecount.toFixed(0)*/});
						dailyStatsIndex ++;
						//console.log("dailyStatsIndex", dailyStatsIndex);
					}
					else {
						postureScore.push({ x: new Date(date), y: 0});
						exercicesCount.push({ x: new Date(date), y: 0});
						inactivityCount.push({ x: new Date(date), y: 0});
						riskyGestureCount.push({ x: new Date(date), y: 0});
					}

				}
				else {
					postureScore.push({ x: new Date(date), y: 0});
					exercicesCount.push({ x: new Date(date), y: 0});
					inactivityCount.push({ x: new Date(date), y: 0});
					riskyGestureCount.push({ x: new Date(date), y: 0});
				}

				date.add(1, 'days');
				dataLoaded = true;
			}

			//console.log("postureScore", postureScore);

			this.setState({
				postureScore,
				exercicesCount,
				inactivityCount,
				riskyGestureCount,
				dataLoaded,
			})
		}
	}

	render()
	{
		return(
			<Container>
				{ !this.state.dataLoaded ? 
					(
						<Loader
							visibility={!this.state.dataLoaded}
							color={ Blue }
						/>
					)
					:
					(
					 <VictoryChart
							scale={{ x: "time" }}
							width={1300}
							height={700}
							//domain={this.state.domain}
							containerComponent={
								<VictoryVoronoiContainer
								/>
								}
							>
							<VictoryLine
								labelComponent={ 
									<VictoryTooltip
										// flyoutComponent={<PostureGraphTooltip/>}
										// renderInPortal={false}
										// flyoutStyle={{
										// 	fontSize: 5,
										// }}
									/>}
								labels={({ datum }) => `Score posture: ${datum.y.toFixed(0)}`}
								data={this.state.postureScore}
								standalone={true}
								style={{
									data: {
										stroke: AreaChartColor3,
										strokeWidth: 1,
									},
								}}
							/>
							<VictoryLine
								labelComponent={
									<VictoryTooltip/>}
								labels={({ datum }) => `${datum.y} exercices`}
								data={this.state.exercicesCount}
								standalone={true}
								style={{
									data: {
										stroke: YellowCardColor1,
										strokeWidth: 1,
									},
								}}
							/>
							<VictoryLine
								labelComponent={
									<VictoryTooltip/>}
								labels={({ datum }) => `${datum.y} inactivitées`}
								data={this.state.inactivityCount}
								standalone={true}
								style={{
									data: {
										stroke: InactivityColor,
										strokeWidth: 1,
									},
								}}
							/>
							<VictoryLine
								labelComponent={
									<VictoryTooltip/>}
								labels={({ datum }) => `${datum.y} geste à risque`}
								data={this.state.riskyGestureCount}
								standalone={true}
								style={{
									data: {
										stroke: Red,
										strokeWidth: 1,
									},
								}}
							/>

							<VictoryAxis
								tickCount={moment(this.state.endtime).diff(this.state.starttime, 'days')}
								tickLabelComponent={<VictoryLabel />}
								tickFormat={t => {
										let d = new Date(t)
										return moment(d).format("DD/MM")
									}
								}
								//offsetY={20}
								style={{
									axis: {
										stroke: 'grey',
										strokeDasharray: 3,
									},
									ticks: {
										stroke: 'grey',
										size: 10,
										fillOpacity: 0.5,
										padding: 10,
									},
									tickLabels: {
										fontSize: 15,
										fontWeight: '400',
										padding: 0,
									},
								}}
							/>
							<VictoryLegend x={550} y={700}
								// title="Legend"
								//centerTitle
								orientation="horizontal"
								titleOrientation="left"
								gutter={10}
								style={{ 
									border: { stroke: "white" },
									title: {fontSize: 15 },
									labels: {fontSize: 15},
								}}
								data={[
									{ name: "Posture", symbol: { fill: AreaChartColor3 } },
									{ name: "Exercices", symbol: { fill: YellowCardColor1, type: "star" } },
									{ name: "inactivitées", symbol: { fill: InactivityColor } },
									{ name: "Gestes à risque", symbol: { fill: Red } }
								]}
								//dataComponent={<CustomLegend size={5}/>}
								/>
						</VictoryChart>
						)}
				</Container>
		);
	}
}

export default ProgressionSection;