import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Box = styled.div`
    margin: 0.5% 2% 1% 2%;
    display: flex;
    max-width: 1356px;
    flex: 1;
    background: ${White};
    border-radius: 10px;
`

export const CardComponent = styled.div`
    display: flex;
    margin-bottom: 20px;
`

export const MiddleCard = styled.div`
    flex: 1;
    margin: 0 20px;
`

export const Cards = styled.div`
    flex: 3;
    margin: 10px 30px;
`

export const News = styled.div`
    flex: 1;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    border-left: 1px solid lightgrey;
`

export const Image = styled.img`
    padding: 0 10px;
`

export const UserInfos = styled.div`
    margin: 10px;
`

