import styled from 'styled-components'

export const Input = styled.input`
    color: ${props => props.color};
    background: ${props => props.background};
    width: ${props => props.width};
    font-size: ${props => props.size};
    font-weight: ${props => props.weight};
    border-radius: ${props => props.radius};
    border-top-left-radius: ${props => props.topRadius};
    border-top-right-radius: ${props => props.topRadius};
    border-bottom-left-radius: ${props => props.bottomRadius};
    border-bottom-right-radius: ${props => props.bottomRadius};
    border: ${props => props.border};
    border-bottom: ${props => props.borderBottom};
    border-top: ${props => props.borderTop};
    height: ${props => props.height};
    outline: ${props => props.outline};
    padding: ${props => props.padding};
    margin: ${props => props.margin};
    ::placeholder {
        color: ${props => props.placeColor};
    }
    :-webkit-autofill {
        box-shadow: 0 0 0 100px white inset;
    }
    :focus {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2),
            0 6px 10px 0 rgba(0, 0, 0, 0.19);
    }
`
