import React, { Component } from 'react';
import { Box, Head, Content, CardDiv, Cards } from './styled';
import { Table as WaviiTable } from '../../components/Table';
import {
	IndicatorTable,
	YellowCardColor1,
	YellowCardColor2,
} from '../../assets/colors';
import IndicatorHead from '../../components/IndicatorHead';
import * as Services from '../../core/auth/authService';
import Api from '../../core/api';
import moment from 'moment';

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Divider,
	Paper,
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: IndicatorTable,
		// color: White,
		fontSize: 20,
		fontWeight: 700,
	},
	body: {
		fontSize: 18,
	},
}))(TableCell);

const StyledTableHead = withStyles(() => ({
	root: {
		borderRadius: 10,
	}
}))(TableHead);


class Indicator extends Component {
	state = {
		userRank: [],
		teamRank: [],
		loading: false,
	}

	componentDidMount() {
		this.checkToken()
	}

	checkToken = () => {
		let token = Services.getToken()
		if (!token) {
			document.location.assign('/auth/login');
		}
		else {
			this.fetchData();
		}
	}


	fetchData = () => {
		//let startDate =moment().utc().startOf('day').toISOString();
		let endDate = moment().utc().endOf('day').toISOString();

		this.setState({ loading: true })

		Api.groupContestDates().then(resp =>{
			console.log("groupContestDates", resp);
			if (resp && !resp.error) {
				let contestStartDate = moment(resp.data.StartDate).utc().startOf('day').toISOString();
				let contestEndDate = moment(resp.data.EndDate).utc().startOf('day').toISOString();

				endDate = moment(endDate).isBefore(contestEndDate) ? endDate : contestEndDate;

				Api.usersRank(contestStartDate, endDate, "exercisescore", -1).then(data => {
					console.log(data);
					if (data && data.data && !data.data.error) {
						this.setState({
							userRank: data.data,
						})
					} else if (data.status === 401) {
						Services.deleteToken()
						document.location.assign('/auth/login')
					}
					this.setState({ loading: false })
				})

				Api.teamRank(contestStartDate, endDate, "exercisescore", -1).then(data => {
					console.log(data);
					if (data && data.data && !data.data.error) {
						this.setState({
							teamRank: data.data,
						})
					} else if (data.status === 401) {
						Services.deleteToken()
						document.location.assign('/auth/login')
					}
					this.setState({ loading: false })
				})
			}
		});
	}

	render() {
		return (
			<Box>
				<Cards>
					<CardDiv margin={true}>
						<Head>
							<IndicatorHead
								card={'Exercise'}
								title={'Exercices SOLO'}
								color1={YellowCardColor1}
								color2={YellowCardColor2}
							/>
						</Head>
						<Content>
							<WaviiTable
								background={IndicatorTable}
								columns={['Pseudo', 'Pts', 'Rang']}
								headBorder={true}
								data={
								this.state.userRank.length > 0
									? this.state.userRank
									: false
								}
								points="exercisescore"
							/>
							<TableContainer component={Paper}>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<StyledTableCell align="left">Pseudo</StyledTableCell>
											<StyledTableCell align="center">Points</StyledTableCell>
											<StyledTableCell align="right">Rang</StyledTableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{ this.state.userRank.map((row) => (
											<TableRow key={row.name}>
												<StyledTableCell align="left">{row.publicname}</StyledTableCell>
												<StyledTableCell align="center">{row.exercisescore.toFixed(0)}</StyledTableCell>
												<StyledTableCell align="right">{row.rank}</StyledTableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Content>
					</CardDiv>
					<CardDiv>
						<Head>
							<IndicatorHead
								card={'Exercise'}
								title={'Exercices TEAM'}
								color1={YellowCardColor1}
								color2={YellowCardColor2}
							/>
						</Head>
						<Content>
							<WaviiTable
								background={IndicatorTable}
								columns={['Team', 'Pts', 'Rang']}
								headBorder={true}
								data={
								this.state.teamRank.length > 0
									? this.state.teamRank
									: false
								}
								points="exercisescore"
							/>
						</Content>
					</CardDiv>
				</Cards>
			</Box>
		)
	}
}

export default Indicator
