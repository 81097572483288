import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Main = styled.div`
    flex: 1;
    color: ${White};
    background: ${props =>
        props.color1 &&
        props.color2 &&
        'linear-gradient(to bottom right, ' +
            props.color1 +
            ' 0%,' +
            props.color2 +
            ' 100%)'};
    height: 230px;
    min-width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
`

export const Head = styled.div`
    display: flex;
`

export const Topleft = styled.div`
    flex: 1;
    padding: 10px;
    overflow: visible;

    svg {
        width: 50px;
        height: 50px;
    }
`

export const TopRight = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin: 10px;
`

export const Text = styled.div`
    border: 1px solid white;
    border-radius: ${props => (props.radius ? props.radius : '0px')};
    padding: 5px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    height: ${props => props.height + 'px'};
    width: ${props => (props.width ? props.width + 'px' : 'fit-content')};
    font-size: 20px;
`

export const Body = styled.div`
    display: flex;
    flex: 1;
    padding: 20px;
    padding-bottom: 0;
    padding-top: 10px;
`

export const BodyLeft = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

export const BodyMiddle = styled.div`
    border: 0.5px solid ${White};
    margin: 0 5px;
`

export const BodyRight = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const BodyText = styled.div`
    font-weight: bold;
    text-align: center;
    line-height: 22px;
    flex: 1;
`

export const BodyNumber = styled.div`
    text-align: center;
    vertical-align: center;
    flex: 1;
    font-weight: bold;
    font-size: 25px;
`
