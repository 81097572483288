import styled from 'styled-components'
import { White, Red, fontColor, RedNumber } from '../../assets/colors'

export const Box = styled.div`
    margin: 0.5% 2% 1% 2%;
    flex: 1;
    max-width: 1356px;
`

export const JourneyHead = styled.div`
    background: ${White};
    color: ${Red};
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
`

export const JourneyContent = styled.div`
    display: flex;
`

export const JourneyLeft = styled.div`
    flex: 1;
    background: ${White};
    margin: 10px 0;
    border-radius: 10px;
    display: flex;
    min-height: 600px;
    box-sizing: border-box;
`
export const AreaRecapDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 2;
`

export const AreaChart = styled.div`
    flex: 1;
`

export const PieDiv = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
`

export const PostureRecap = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 20px;
`

export const ProgressHead = styled.div`
    font-size: 20px;
    padding: 15px 0;
    font-weight: bold;
`

export const LineDiv = styled.div`
    display: flex;
    padding-top: 10px;
    padding-bottom: 30px;
`

export const StatusLineDiv = styled.div`
    padding-right: 3px;
    flex: ${props => (props.flex ? props.flex : 0)};

    transition: flex 1s ease-in-out;
`

export const StatusTimeDiv = styled.div`
    display: flex;
    padding-bottom: 20px;
`

export const BulletDiv = styled.div`
    padding-right: 30px;
    display: flex;
    align-self: center;
`

export const LabelDiv = styled.div`
    padding-right: 30px;
    min-width: ${props => props.minWidth + 'px'};
    color: ${fontColor};
`

export const TimeDiv = styled.div`
    /* padding-right: 30px; */
    font-weight: bold;
`

export const JourneyRight = styled.div`
    flex: 1;
`

export const JourneyRightUp = styled.div`
    display: flex;
    background: ${White};
    margin-top: 10px;
    margin-left: 10px;
    border-radius: 10px;
    padding: 20px;
    min-height: 350px;
    box-sizing: border-box;
`

export const FlexonDiv = styled.div`
    flex: 1;
`

export const RightUpHead = styled.div`
    font-size: 25px;
    color: ${fontColor};
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.end ? 'flex-end' : 'flex-start')};
`

export const RightUpImage = styled.div`
    display: flex;
    justify-content: flex-end;
    min-height: 100px;
    /* padding-right: 10px; */
    padding-bottom: 5px;
`

export const RightUpStatusLine = styled.div`
    display: flex;
    padding-bottom: 20px;
`

export const RightUpTimeDiv = styled.div`
    display: flex;
    padding-top: 20px;
    align-self: flex-start;
`

export const InclineDiv = styled.div`
    flex: 1;
    padding-left: 150px;
`

export const JourneyRightDown = styled.div`
    display: flex;
    flex-direction: column;
    background: ${White};
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 20px;
    min-height: 250px;
    box-sizing: border-box;
`

export const ScoreHead = styled.div`
    color: ${Red};
    font-size: 25px;
    display: flex;
    font-weight: bold;
    justify-content: center;
    padding-bottom: 30px;
`

export const ScoreBody = styled.div`
    padding: 10px 0;
    display: flex;
`

export const ScoreCalculation = styled.div`
    flex: 1;
`

export const ScoreUp = styled.div``

export const Summary = styled.div`
    display: flex;
`

export const SummaryLeft = styled.div`
    display: flex;
    padding: 0 10px;
`

export const IconTag = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

export const SummaryIcon = styled.div`
    min-height: 40px;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
`

export const IconNumber = styled.div`
    color: ${RedNumber};
    font-size: 20px;
    font-weight: bold;
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
`

export const SummaryTag = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    font-size: 20px;
`

export const VerticalLine = styled.div`
    border-right: 2px dotted lightgray;
    margin: 0px 30px 5px 30px;
`

export const SummaryRight = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const PostureScore = styled.div`
    padding-right: 20px;
`

export const ScoreDown = styled.div`
    display: flex;
    justify-content: center;

    padding: 10px 0;
`

export const Calculate = styled.div`
    flex: 1;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: ${Red};
    font-weight: bold;
`
export const NumberFont = styled.span`
    font-size: 25px;
`

export const Operator = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Red};
    font-weight: bold;
`

export const ScorePicsDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
`

export const EqualToDiv = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: ${Red};
    font-weight: bold;
    padding: 0 10px;
`

export const TotalScore = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ProgressionHead = styled.div`
    background: ${White};
    color: ${Red};
    padding: 10px 0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
`

export const ProgressionContent = styled.div`
    display: flex;
`

export const ProgressionLeft = styled.div`
    flex: 1;
    margin: 10px 0;
    border-radius: 10px;
    padding: 20px;
    background: ${White};
    box-sizing: border-box;
`

export const ProgressionRight = styled.div`
    flex: 1;
    display: flex;
    margin: 10px 0;
    border-radius: 10px;
    padding: 20px;
    background: ${White};
    margin-left: 10px;
    border-radius: 10px;
    box-sizing: border-box;
`
export const ProgressionPoints = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 10px;
`

export const ProgressionMessage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: ${Red};
    font-weight: bold;
    padding-left: 20px;
    padding-right: 50px;
`
