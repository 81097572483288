export const Red = '#FF877C'

export const RedNumber = '#FF2C4D'

export const Logo = '#EDE1D4'

export const Shadow = '#89969F'

export const profileBack = '#EAD2D3'

export const AppBack = '#D8D8D8'

export const White = '#fff'

export const Black = '#000'

export const Grey = '#CECECF'

export const Blue = '#4ACDFE'

export const InactivityColor = '#63abba'

export const ExerciseYellow = '#ffbd64'

export const ProgressBlue = '#47B6FF'

export const IndicatorTable = '#F2F3F4'

export const BlueCardColor1 = '#46ADBC'

export const BlueCardColor2 = '#7BBCC6'

export const RedCardColor1 = '#FF003E'

export const RedCardColor2 = '#FF668B'

export const YellowCardColor1 = '#FFBA50'

export const YellowCardColor2 = '#FECC81'

export const fontColor = '#A6ACAF'

export const AreaChartColor1 = '#FDFDFD'

export const AreaChartColor2 = '#B9E3FF'

export const AreaChartColor3 = '#7CCBFF'

export const AreaChartColor4 = '#059CFF'

export const AreaChartBorder = '#47B6FF'
