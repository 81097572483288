import React, { Component } from 'react'
import { Box, Left, Right } from './styled'
import { ReactComponent as Inactive } from '../../assets/images/ic_journee_inactivite.svg'
import { ReactComponent as Alerts } from '../../assets/images/ic_journee_alerte.svg'
import { ReactComponent as Exercise } from '../../assets/images/ic_journee_exercices.svg'

class IndicatorHead extends Component {
    render() {
        let { color1, color2, card } = this.props
        return (
            <Box color1={color1} color2={color2}>
                <Left>
                    {card && card === 'Inactive' ? (
                        <Inactive />
                    ) : card === 'Alerts' ? (
                        <Alerts />
                    ) : (
                        <Exercise />
                    )}
                </Left>
                <Right>{this.props.title}</Right>
            </Box>
        )
    }
}

export default IndicatorHead
