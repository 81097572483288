import styled from 'styled-components'
import { White, Red } from '../../assets/colors'

export const Box = styled.div`
    margin: 0.5% 2% 1% 2%;
    flex: 1;
    max-width: 1356px;
    padding: 10px 30px;
    background: ${White};
    border-radius: 15px;
`
export const Heading = styled.div`
    font-size: 30px;
    color: ${Red};
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
`
