import React, { Component } from 'react'
import { Div, CopyRight, Contact } from './styled'

export default class Footer extends Component {
    render() {
        return (
            <Div>
                <CopyRight>Copyright 2019 Percko - CGU - V1.1</CopyRight>
                <Contact>Contact Us</Contact>
            </Div>
        )
    }
}
