import styled from 'styled-components'
import { White } from '../../assets/colors'

export const DateDiv = styled.div`
    .dates {
        min-width: 200px;
        padding: 10px 15px;
        border-radius: 30px;
        font-size: 20px;
        color: ${White};
        font-weight: bold;
        outline: none;
        background-color: rgba(255, 255, 255, 0.2);
        ::placeholder {
            color: ${White};
        }
        border: 2px solid #fff;
    }
`
