import styled from 'styled-components'
import { AppBack, Red } from './assets/colors'

export const Div = styled.div`
    /*display: flex;
    position: absolute;*/
    width: 100%;
    height: 100%;
    /*flex-direction: column;*/
    background: #D8D8D8;
`

export const Content = styled.div`
    /*flex: 1;*/
    display: flex;
    justify-content: center;
    background: ${props => (props.logged ? AppBack : Red)};
`
