import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Main = styled.div`
    flex: 1;
    color: ${White};
    background: ${props =>
        props.color1 &&
        props.color2 &&
        'linear-gradient(to bottom right, ' +
            props.color1 +
            ' 0%,' +
            props.color2 +
            ' 100%)'};
    height: 230px;
    min-width: 300px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
`

export const HeadDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    flex: 1;
`

export const Text = styled.div`
    border: ${props => (props.border ? '1px solid white' : 'none')};
    border-radius: ${props => (props.radius ? props.radius : '0px')};
    padding: 5px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    height: ${props => props.height + 'px'};
    width: ${props => (props.width ? props.width + 'px' : 'fit-content')};
    font-size: 20px;
`

export const NumberTag = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
        font-size: 25px;
    }
`
