import React, { Component } from 'react';
import { Table as WaviiTable } from '../../components/Table';
import { Box, Heading } from './styled';
import { Red, White } from '../../assets/colors';
import * as Services from '../../core/auth/authService';
import Api from '../../core/api';
import Loader from '../../components/Loader';
import moment from 'moment';

import { ReactComponent as Inactive } from '../../assets/images/inactivite_blue.svg';
import { ReactComponent as Exercise } from '../../assets/images/yellow_exercise.svg';
import { ReactComponent as Alerts } from '../../assets/images/alerte_red.svg';

import { isMobile } from "react-device-detect";

import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Divider,
	Paper,
	Grid,
	Badge,
	Chip,
} from '@material-ui/core';

import { withStyles } from '@material-ui/styles';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: Red,
		color: White,
		fontSize: 20,
		fontWeight: 700,
	},
	body: {
		fontSize: 18,
	},
}))(TableCell);

const StyledTableHead = withStyles(() => ({
	root: {
		borderRadius: 10,
	}
}))(TableHead);


class General extends Component {
	state = {
		data: [],
		loading: false,
	}

	componentDidMount() {
		this.checkToken()
	}

	checkToken = () => {
		let token = Services.getToken()
		if (!token) {
			this.props.history.push('/auth/login')
		} else {
			this.fetchData()
		}
	}

	fetchData = () => {
		//let startDate =moment().utc().startOf('day').toISOString();
		let endDate = moment().utc().endOf('day').toISOString();

		this.setState({ loading: true })

		Api.groupContestDates().then(resp =>{
			console.log("groupContestDates", resp);
			if (resp && !resp.error) {
				let contestStartDate = moment(resp.data.StartDate).utc().startOf('day').toISOString();
				let contestEndDate = moment(resp.data.EndDate).utc().startOf('day').toISOString();

				endDate = moment(endDate).isBefore(contestEndDate) ? endDate : contestEndDate;

				let arr = []
				Api.usersRank(contestStartDate, endDate, "globalscore", -1).then(data => {
					console.log(data);
					if (data && data.data && !data.data.error) {
						arr = data.data

						arr.sort((a, b) => b.globalscore - a.globalscore)

						this.setState({
							data: arr,
						})
					} else if (data.status === 401) {
						Services.deleteToken()
						document.location.assign('/auth/login')
					}
					this.setState({ loading: false })
				})
			}
		});

	}

	render() {
		return (
			<Box>
				<Heading>CLASSEMENT GÉNÉRAL</Heading>

				{this.state.loading ? (
					<Loader visibility={this.state.loading} color={Red} />
				) : (
					this.state.data.length !== 0 && (
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="left">Pseudo</StyledTableCell>
										<StyledTableCell align="center">Points</StyledTableCell>
										<StyledTableCell align="center"></StyledTableCell>
										<StyledTableCell align="right">Rang</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{ this.state.data.map((row) => (
										<TableRow key={row.name} styl>
											<StyledTableCell align="left">{row.publicname}</StyledTableCell>
											<StyledTableCell align="center">{row.globalscore.toFixed(0)}</StyledTableCell>
											<StyledTableCell align="center">
											{ !isMobile ? 
												(
													<Grid container spacing={3} alignItems="center">
														<Grid item xs={4}>
															<Badge badgeContent={row.inactivitycount.toFixed(0)} color="primary">
																<Inactive />
															</Badge>
														</Grid>
														<Grid item xs={4}>
															<Badge badgeContent={row.riskygesturecount.toFixed(0)} color="primary">
																<Alerts />
															</Badge>
														</Grid>
														<Grid item xs={4}>
															<Badge badgeContent={row.exercisecount.toFixed(0)} color="primary">
																<Exercise />
															</Badge>
														</Grid>
													</Grid>
												)
												:
												(<div></div>)
											}
											</StyledTableCell>
											<StyledTableCell align="right">{row.rank}</StyledTableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)
				)}
			</Box>
		)
	}
}

export default General;
