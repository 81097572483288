import React, { Component } from 'react'
import Input from '../../../components/Input'
import * as Services from '../../../core/auth/authService'
import Api from '../../../core/api'
import {
    Box,
    Logo,
    InputBox,
    ConnectDiv,
    ForgetPasswordDiv,
    SignUpDiv,
} from './styled'
import Button from '../../../components/Button'
import { Red, White } from '../../../assets/colors'
// import { ReactComponent as SideArrow } from '../../../assets/images/sideArrow.svg'
import LoaderClass from '../../../components/Loader'
import { withSnackbar } from 'notistack';
import { ReactSVG } from 'react-svg'

class Login extends Component {
    state = {
        email: null,
        password: null,
        loading: false,
    }

    componentDidMount() {
        this.fetchData()
        this.checkToken()
    }

    handleKeyPress = () => {
        // eslint-disable-next-line
        if (window.event.keyCode == 13) {
            this.handleLogin()
        }
    }

    checkToken = () => {
        let token = Services.getToken()
        if (token) {
            this.props.history.push('/dashboard')
        }
    }

    fetchData = async () => {
        await Api.groupLogo().then(data => {
            if (data && data.data && !data.data.error) {
                console.log(data.data.url);
                this.setState({
                    logo: data.data.url,
                })
            }
        })
    }

    handleChange = event => {
        this.setState({
            [event.target.id]: event.target.value,
        })
    }

    handleSignUp = () => {
        this.props.history.push('/auth/register')
    }

    handleLogin = () => {
        let email = this.state.email
        let password = this.state.password

        if (email && password) {
            this.setState({
                loading: true,
            })
            Services.login(email, password).then(resp => {
                console.log(resp);
                this.setState({ loading: false })

                if (resp.error.includes("ErrNotGroupMember")) {
                    let userID = resp.error.split(':')[1].trim().replace('[', '').replace(']', '');
                    document.location.assign('/join/' + userID);
                }
                else if (resp.error.includes("ErrWrongCredentials")) {
                    this.props.enqueueSnackbar('identifiant et/ou mot de passe incorrect', { variant: 'error'});
                }
                else if (resp.error.includes("ErrGroupIncompleteProfile")) {
                    console.log("ErrGroupIncompleteProfile");
                    document.location.assign('/completeProfile')
                }
                else if (resp && resp.token) {
                    document.location.assign('/dashboard')
                } 

            })
        } else {
            alert('Enter the missing fields')
        }
    }

    render() {
        return (
            <Box>
                <Logo>
                    <ReactSVG
                        src={this.state.logo}
                        wrapper="span"
                        className="wrapper-class-name"
                    />
                </Logo>
                <InputBox>
                    <Input
                        width={'250px'}
                        id={'email'}
                        onChange={this.handleChange}
                        border={'none'}
                        type={'text'}
                        outline={'none'}
                        topRadius={'10px'}
                        borderBottom={'1px solid black'}
                        padding={'20px 30px'}
                        size={'20px'}
                        placeholder={'Email'}
                    />
                    <Input
                        width={'250px'}
                        id={'password'}
                        type={'password'}
                        onChange={this.handleChange}
                        border={'none'}
                        outline={'none'}
                        bottomRadius={'10px'}
                        padding={'20px 30px'}
                        size={'20px'}
                        placeholder={'Password'}
                        onKeyPress={this.handleKeyPress}
                    />
                </InputBox>
                <ConnectDiv>
                    <Button
                        width={'250px'}
                        onClick={this.handleLogin}
                        backgroundColor={Red}
                        textColor={White}
                        fontSize={20}
                        bold={true}
                        padding={'15px 30px'}
                        borderRadius={30}
                        borderColor={White}
                    >
                        Se connecter
                        {/* <span
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <SideArrow />
                        </span> */}
                    </Button>
                </ConnectDiv>
                <ForgetPasswordDiv
                    onClick={() =>
                        this.props.history.push('/auth/forgot_password')
                    }
                >
                    Mot de passe oublié ?
                </ForgetPasswordDiv>
                <LoaderClass visibility={this.state.loading} color={White} />
                <SignUpDiv>
                    <Button
                        onClick={this.handleSignUp}
                        backgroundColor={Red}
                        textColor={White}
                        fontSize={20}
                        bold={true}
                        padding={'15px 30px'}
                        borderRadius={35}
                        borderColor={White}
                    >
                        Créer un nouveau compte
                    </Button>
                </SignUpDiv>
            </Box>
        )
    }
}

export default withSnackbar(Login);
