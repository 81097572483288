import React, { Component } from 'react'
import { MessageDate, MessageAuthor, MessageContent, MessageComponent } from './styled.js'
import ReactHtmlParser from 'react-html-parser'; 

export default class Message extends Component {
  render() {
    let {message} = this.props;
    let date = new Date(message.publicationdate).toLocaleString();
    let content = ReactHtmlParser(message.content);
    return (
      <MessageComponent>
        <MessageDate>
          { date }
        </MessageDate>
        <MessageAuthor>
          { message.author }
        </MessageAuthor>
        <MessageContent>
          { content }
        </MessageContent>
      </MessageComponent>
    )
  }
}