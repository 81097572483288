import React from 'react'
import { Box, Line } from './styled'

export const ProgressLine = props => {
    return (
        <Box>
            <Line color={props.color} shadow={props.shadow} />
        </Box>
    )
}
