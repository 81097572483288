import React, { Component } from 'react';
import { Box, Logo, InputBox, ConnectDiv } from './styled';
import { ReactComponent as Total } from '../../../assets/images/totalLogoBig.svg';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import { Red, White } from '../../../assets/colors';
import ValidationMessage from '../../../components/ValidationMessage';
import { withRouter } from "react-router-dom";
import * as Services from '../../../core/auth/authService';
import { withSnackbar } from 'notistack';


class ResetPassword extends Component {

	state={
		email: '',
		password: '',
		passwordValid: false,
		passwordConfirm: '',
		passwordConfirmValid: false,
		errorMsg: {},
		formValid: false,
		resetPasswordToken: '',
	}

	componentDidMount() {
		const token = this.props.match.params.token;
		const { email, key } = JSON.parse(atob(token));
		console.log("resetPasswordToken:", token);
		this.setState({
			resetPasswordToken: token,
			email: email,
		});
	}


	handleKeyPress = () => {
		// eslint-disable-next-line
		if (window.event.keyCode == 13) {
		}
	}

	handleSubmit = () => {
		Services.passwordReset(this.state.password, this.state.email, this.state.resetPasswordToken)
			.then((resp) => {
				console.log(resp);
				if (resp.user_id.length > 1) {
					this.props.enqueueSnackbar('Mot de passe mis à jour', { variant: 'success'});
				} else {
					this.props.enqueueSnackbar('Lien expiré', { variant: 'error'});
				}
			});
	}

	validateForm = () => {
		const { passwordValid, passwordConfirmValid } = this.state;
		this.setState({
			formValid: passwordValid && passwordConfirmValid,
		})
	}

	updatePassword = (password) => {
		this.setState({password}, this.validatePassword);
	}

	validatePassword = () => {
		const { password, passwordConfirm } = this.state;
		let passwordValid = true;
		let passwordConfirmValid = true;
		let errorMsg = {...this.state.errorMsg};

		// must be 8 chars
		// and 3 of the following:
		// must contain a number
		// must contain a special character
		// must contain lowercase
		// must contain uppercase
		let pattern1 = /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*)$/
		let pattern2 = /^(.{0,7}|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/
		let pattern3 = /^(.{0,7}|[^0-9]*|[^a-z]*|[a-zA-Z0-9]*)$/
		let pattern4 = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/


		if (password.length < 8) {
			passwordValid = false;
			errorMsg.password = 'Votre mot de passe doit contenir au moins 8 caractères.';
		} else if (pattern1.test(password) && pattern2.test(password) && pattern3.test(password) && pattern4.test(password) ){
			passwordValid = false;
			errorMsg.password = 'Votre mot de passe doit contenir une combinaison d\'au moins 3 des éléments suivants: majuscule, minuscule, chiffre, symbole';
		}

		this.setState({passwordValid, errorMsg}, this.validateForm);

		if (password !== passwordConfirm) {
			passwordConfirmValid = false;
			errorMsg.passwordConfirm = 'Les mots de passes de correspondent pas.';
		}

		this.setState({passwordConfirmValid, errorMsg}, this.validateForm);
	}

	updatePasswordConfirm = (passwordConfirm) => {
		this.setState({passwordConfirm}, this.validatePasswordConfirm);
	}

	validatePasswordConfirm = () => {
		const {passwordConfirm, password} = this.state;
		let passwordConfirmValid = true;
		let errorMsg = {...this.state.errorMsg};

		if (password !== passwordConfirm) {
			passwordConfirmValid = false;
			errorMsg.passwordConfirm = 'Les mots de passes de correspondent pas.';
		}

		this.setState({passwordConfirmValid, errorMsg}, this.validateForm);
	}

	render() {
		return (
			<Box>
				<Logo>
					<Total />
				</Logo>
				<InputBox>
					<Input
						type="password"
						width={'250px'}
						border={'none'}
						outline={'none'}
						topRadius={'10px'}
						borderBottom={'1px solid black'}
						padding={'20px 30px'}
						size={'20px'}
						placeholder={'Nouveau mot de passe'}
						onChange={(e) => this.updatePassword(e.target.value)}
					/>
					<Input
						type="password"
						width={'250px'}
						border={'none'}
						outline={'none'}
						bottomRadius={'10px'}
						padding={'20px 30px'}
						size={'20px'}
						placeholder={'Confirmer'}
						onKeyPress={this.handleKeyPress}
						onChange={(e) => this.updatePasswordConfirm(e.target.value)}
					/>
				</InputBox>
				<div>
					<ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
					<ValidationMessage valid={this.state.passwordConfirmValid} message={this.state.errorMsg.passwordConfirm} />
				</div>
				<ConnectDiv>
					<Button
						width={'200px'}
						onClick={this.handleSubmit}
						backgroundColor={Red}
						textColor={White}
						fontSize={20}
						bold={true}
						padding={'15px 30px'}
						borderRadius={30}
						borderColor={White}
						disabled={!this.state.formValid}
					>
						Valider
					</Button>

				</ConnectDiv>
				<Button
						width={'400px'}
						onClick={()=>{document.location.assign('/auth/login')}}
						backgroundColor={Red}
						textColor={White}
						fontSize={20}
						bold={true}
						padding={'15px 30px'}
						borderRadius={30}
						borderColor={White}
					>
						&lt; Retour à la page de connexion
					</Button>
			</Box>
		)
	}
}

export default withSnackbar(withRouter(ResetPassword));
