import styled from 'styled-components'
import { White } from '../../../assets/colors'

export const Box = styled.div`
    margin: 0.5% 2% 1% 2%;
    flex: 1;
    max-width: 1356px;
    color: ${White};
`

export const Content = styled.div`
    background: ${White};
    padding: 20px;
    margin: 0 20%;
    border-radius: 10px;
    font-size: 15px;
    justify-content: center;
    text-align: center;
`

export const Logo = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0;
`

export const MinHead = styled.div`
    text-align: center;
    color: white;
    font-size: 22px;
    font-weight: bold;
`

export const InformationDiv = styled.div`
    padding: 20px 50px;
`

export const Label = styled.div`
    font-size: 22px;
    color: ${White};
    padding-bottom: 10px;
`

export const ItemDiv = styled.div`
    padding: 5px 0px;
    margin: 0px 10px;
`

export const SubmitDiv = styled.div`
    margin: 15px 0;
    text-align: center;
`