import React, { Component } from 'react';
import { Div, Content } from './App.styled';
import NavBar from './components/Navbar';
import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './screens/Dashboard';
import Login from './screens/Auth/Login';
import Forgot from './screens/Auth/ForgetPassword';
import Reset from './screens/Auth/ResetPassword';
import Join from './screens/Auth/Join';
import completeProfile from './screens/Auth/completeProfile';
import Status from './screens/Status';
import Exercices from './screens/ClassementExercices';
import General from './screens/ClassmentGeneral';
import Teams from './screens/Teams';
import Rules from './screens/Rules';
import Profile from './screens/Profile';
import Invite from './screens/Invite';
import SignUp from './screens/Auth/signUp';
import Footer from './components/Footer';
import * as Services from './core/auth/authService';

export default class Main extends Component {
    constructor() {
        super()
        this.state = {
            login: false,
        }
    }
    componentDidMount() {
        this.checkToken()
    }
    checkToken = () => {
        let token = Services.getToken()
        if (token) {
            this.setState({ login: true })
        }
    }

    render() {
        return (
            <Div>
                <NavBar logged={this.state.login} />
                <Content logged={this.state.login}>
                    <Switch>
                        <Route exact path="/auth/register" component={SignUp} />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route path="/auth/login" component={Login} />
                        <Route
                            path="/auth/forgot_password"
                            component={Forgot}
                        />
                        <Route
                            path="/auth/reset_password/:token"
                            component={Reset}
                        />
                        <Route exact path="/status" component={Status} />
                        <Route exact path="/exercices" component={Exercices} />
                        <Route exact path="/general" component={General} />
                        <Route exact path="/teams" component={Teams} />
                        <Route exact path="/rules" component={Rules} />
                        <Route exact path="/profile" component={Profile} />
                        <Route exact path="/invite/:id" component={Invite} />
                        <Route exact path="/join/:id" component={Join} />
                        <Route exact path="/completeProfile" component={completeProfile} />
                        <Redirect from="*" to="/dashboard" />
                    </Switch>
                </Content>
                <Footer />
            </Div>
        )
    }
}
