import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import {
	Div,
	Head,
	Logo,
	Profile,
	MenuItems,
	StyledNAvLink,
	Link,
	//Menu,
	Organisation,
} from './styled';
import Percko from '../../assets/images/logo_pk_pro.png';
import Gravatar from 'react-gravatar';
import * as Services from '../../core/auth/authService';
import Api from '../../core/api';

import {
	Grid,
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	Button,
	ListItem,
	ListItemIcon,
	ListItemText,
	Divider,
	List,
	Drawer,
	MenuItem,
	Menu,
} from '@material-ui/core';

import { 
	Menu as MenuIcon,
	Dashboard as DashboardIcon,
	Group as TeamIcon,
	Equalizer as GeneralIcon,
	FitnessCenter as ExerciseIcon,
	ShowChart as StatusIcon,
	AccountCircle as ProfileIcon,
	MeetingRoom as LogoutIcon,
} from '@material-ui/icons';
import { NavLink, Link as RouterLink } from 'react-router-dom'


export default class NavBar extends Component {
	state = {
		clicked: false,
		name: '',
		anchorEl: null,
		open: false,
	}

	componentDidMount() {
		this.fetchData();
	}

	handleLogout = () => {
		Services.logOut().then(data => {
			document.location.assign('/auth/login')
		})
	}

	fetchData = () => {
		Api.groupName()
		.then(resp => {
			if (resp && resp.data) {
				console.log(resp.data);
				this.setState({
					...this.state,
					name: resp.data,
				});
			}
		});
	}

	toggleDrawer = (anchor, open) => (event) => {
		console.log(event);
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
			this.setState({ ...this.state, [anchor]: open });
	};

	list = (anchor) => (
		<div
			role="presentation"
			onClick={this.toggleDrawer(anchor, false)}
			onKeyDown={this.toggleDrawer(anchor, false)}
		>
			<List>
				<ListItem button component={RouterLink} to="/dashboard" key={0}>
					<ListItemIcon> <DashboardIcon /> </ListItemIcon>
					<ListItemText primary={"DASHBOARD"} />
				</ListItem>
				<ListItem button component={RouterLink} to="/general" key={1}>
					<ListItemIcon> <GeneralIcon /> </ListItemIcon>
					<ListItemText primary={"CLASSEMENT GENERAL"} />
				</ListItem>
				<ListItem button component={RouterLink} to="/exercices" key={2}>
					<ListItemIcon> <ExerciseIcon /> </ListItemIcon>
					<ListItemText primary={"CLASSEMENT EXERCICES"} />
				</ListItem>
				<ListItem button component={RouterLink} to="/teams" key={3}>
					<ListItemIcon> <TeamIcon /> </ListItemIcon>
					<ListItemText primary={"EQUIPES"} />
				</ListItem>
				<ListItem button component={RouterLink} to="/status" key={4}>
					<ListItemIcon> <StatusIcon /> </ListItemIcon>
					<ListItemText primary={"MES STATS"} />
				</ListItem>
			</List>
			<Divider />
			<List>
				<ListItem button component={RouterLink} to="/profile" key={4}>
					<ListItemIcon> <ProfileIcon /> </ListItemIcon>
					<ListItemText primary={"PROFIL"} />
				</ListItem>
				<ListItem button key={4} onClick={(e) => {this.handleClose(e); this.handleLogout();}}>
					<ListItemIcon> <LogoutIcon /> </ListItemIcon>
					<ListItemText primary={"DÉCONNEXION"} />
				</ListItem>
			</List>
		</div>
	);

	handleMenu = (event) => {
		console.log(event);
		this.setState({
			...this.state,
			anchorEl: event.currentTarget,
			open: true,
		});
	};

	handleClose = () => {
		this.setState({
			...this.state,
			anchorEl: null,
			open: false,
		});
	};

	//open = Boolean(this.state.anchorEl);

	render() {
		const anchor = "right";
		return (
			<Div logged={this.props.logged}>
				<AppBar
					position="static"
					>
					<Toolbar style={{background: '#ff877c'}}>
						<Typography variant="h6" align="center" style={{flexGrow: 1,}}>
							{this.state.name}
						</Typography>
						{
							isMobile ?
							(
								<IconButton edge="end" color="inherit" aria-label="menu" onClick={this.toggleDrawer(anchor, true)}>
									<MenuIcon />
								</IconButton>
							)
							:
							(
								<Gravatar onClick={(e) => {console.log(e); this.handleMenu(e)}} email={Services.getUserEmail()} size={50} style={{borderRadius: "25px",}} />
							)
						}
						<Menu
							id="menu-appbar"
							anchorEl={this.state.anchorEl}
							anchorOrigin={{
							  vertical: 'top',
							  horizontal: 'right',
							}}
							keepMounted
							transformOrigin={{
							  vertical: 'top',
							  horizontal: 'right',
							}}
							open={this.state.open}
							onClose={this.handleClose}
						>
							<MenuItem>
								<NavLink exact to="/profile" onClick={this.handleClose} >Profil</NavLink>
							</MenuItem>
							<MenuItem>
								<NavLink exact to="/auth/login" onClick={(e) => {this.handleClose(e); this.handleLogout();}} >Déconnexion</NavLink>
							</MenuItem>
						</Menu>
					</Toolbar>
					{
						isMobile ?
						(
							<div>
							</div>
						)
						:
						(
						<Toolbar align="center" style={{background: '#ffffff' }}>
							<MenuItems>
								<StyledNAvLink exact to="/dashboard">
									<Link>DASHBOARD</Link>
								</StyledNAvLink>
								<StyledNAvLink exact to="/general">
									<Link>CLASSEMENT GENERAL</Link>
								</StyledNAvLink>
								<StyledNAvLink exact to="/exercices">
									<Link>CLASSEMENT EXERCICES</Link>
								</StyledNAvLink>
								<StyledNAvLink exact to="/teams">
									<Link>EQUIPES</Link>
								</StyledNAvLink>
								<StyledNAvLink exact to="/status">
									<Link>MES STATS</Link>
								</StyledNAvLink>
							</MenuItems>
						</Toolbar>
					)
					}
				</AppBar>
				<Drawer anchor={anchor} open={this.state[anchor]} onClose={this.toggleDrawer(anchor, false)}>
					{this.list(anchor)}
				</Drawer>
			</Div>
		)
	}
}
