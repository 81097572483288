import React, { Component } from 'react'
import { LoaderDiv } from './styled'
import Loader from 'react-loader'

export class LoaderClass extends Component {
    render() {
        return (
            <LoaderDiv signup={this.props.signup}>
                <Loader
                    loaded={!this.props.visibility}
                    color={this.props.color}
                    zIndex={200}
                />
            </LoaderDiv>
        )
    }
}

export default LoaderClass
