import styled from 'styled-components'

export const Button = styled.button`
    color: ${props => props.textColor};
    cursor: pointer;
    font-weight: ${props => (props.bold ? 'bold' : '')};
    font-size: ${props => props.fontSize + 'px'};
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : 'white'};
    border-radius: ${props =>
        (props.borderRadius ? props.borderRadius : 0) + 'px'};
    outline: none;
    padding: ${props => props.padding};
    :hover {
        box-shadow: ${props => props.hoverShadow};
    }
    box-shadow: ${props => props.shadow};
    margin: ${props => props.margin + 'px'};
    opacity: ${props => props.opacity};
    width: ${props => props.width};
    height: ${props => props.height};
    border-color: ${props => props.borderColor};
    border-style: solid;
    :disabled {
        background-color: #DB908A;
        color: #e8e4e4;
        border-color: #e8e4e4;
        cursor: not-allowed;
    }
`
