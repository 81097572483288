import React, { Component } from 'react'
// import 'react-dates/initialize'
// import 'react-dates/lib/css/_datepicker.css'
// import { SingleDatePicker } from 'react-dates'
import { DateDiv } from './styled'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export class DatePickerClass extends Component {
    render() {
        return (
            <DateDiv>
                <DatePicker
                    selected={this.props.selected}
                    onChange={this.props.onChange}
                    locale={this.props.locale}
                    maxDate={new Date()}
                    placeholderText="JJ/MM/AAAA"
                    className="dates"
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                    dateFormatCalendar="MMMM"
                    yearDropdownItemNumber={100}
                    scrollableYearDropdown
                />
            </DateDiv>
        )
    }
}

export default DatePickerClass

