import React from 'react'
import { Main, Points, Label } from './styled'
import CountUp from 'react-countup'

export const PointsCircle = props => {
    return (
        <Main
            small={props.small}
            font={props.font}
            size={props.size}
            background={props.background}
            color={props.color}
        >
            <Points font={props.font}>
                <CountUp
                    start={0}
                    end={Number(props.score ? props.score : 0)}
                    duration={3}
                />
            </Points>
            <Label small={props.small} font={props.font}>
                Pts
            </Label>
        </Main>
    )
}
