import React, { Component } from 'react';
import {
	Content,
	Box,
} from './styled';

class Rules extends Component {

	fetchData = () => {
		
	}

	render() {
	return (
		<Box>
			<Content>
				<div>
					Pendant deux semaines, Percko vous invite à participer à son concours pour sensibiliser les populations sédentaires aux problématiques du dos. Reprenez contrôle de votre dos sans contraintes !
				</div>
				<h2>
					Le principe du jeu
				</h2>
				<div>
					Vous êtes par équipe de 3. Vous allez tous les trois porter le Wavii au travail. Grâce à ses capteurs intelligents, Wavii va analyser votre posture et votre activité tout au long de votre journée de travail et remonter les informations sur l’application mobile de votre téléphone en temps réel. Tous les jours votre score va apparaître sur l’application.
				</div>
				<div style={{textAlign: "left", marginTop: "20px"}}>
				Ce score dépend de <b>4 critères </b>:
				<ul>
					<li>La posture (plus vous vous tenez droit, plus vous gagnez de points) </li>
					<li>Les gestes à risques qui sont notés comme des alertes (vous perdez un point à chaque fois que vous mettez votre dos dans une position inadaptée) </li>
					<li>Les exercices que vous réalisez au cours de la journée (vous gagnez 5 points à chaque exercice) </li>
					<li>Les périodes d’inactivités prolongées (vous perdez un point) </li>
				</ul>
				</div>
				<div>
					Votre score et celui de votre collègue sont combinés quotidiennement et apparaîssent sur la plateforme en ligne. Le but du jeu est d’avoir le meilleur score possible à la fin des deux semaines de port de Wavii.
				</div>

				<div>
					Les conseils & Astuces d’utilisation sont accessible directement dans l’application. Des exercices sont aussi proposés pour maximiser votre score !
				</div>
				<div>
					Vous pouvez rentrer en contact avec nous tout au long du jeu si vous rencontrez des soucis avec Wavii ou si vous avez des interrogations sur le jeu. Un chat est mis à disposition dans l’application pour communiquer directement avec nous ! 
				</div>
				<h2>
					Les points incontournables
				</h2>
				<div style={{textAlign: "left", marginTop: "20px"}}>
				<ul>
					<li>Porter le Wavii plus de 6 heures par jour au travail (sinon votre score sera automatiquement ramené à 0 à la fin de la journée).</li>
					<li>Aller régulièrement sur l’application pour suivre votre progression, accéder aux conseils d’utilisation et trouver des astuces pour améliorer votre performance. </li>
					<li>Rentrer en contact directement avec nous sur l’application si vous rencontrez des problèmes.</li>
				</ul>
				</div>
			</Content>
		</Box>
	);
	}
}

export default Rules