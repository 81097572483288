import * as Services from '../fetchServices';
import urlService from '../urlServices';
import queryString from 'query-string';

var single = urlService.single
var group = urlService.group
var UserStats = {
	getUserHistory: userHistory,
	groupHistory: groupHistory,
	groupHistoryWeek: groupHistoryWeek,
	singleUserDaily: singleUserDaily,
	teamRank: teamRank,
	usersRank: usersRank,
	userScores: userScores,
	singleTeamStatus: singleTeamStatus,
	groupStats: groupStats,
	groupMessages: groupMessages,
	groupLogo: groupLogo,
	groupName: groupName,
	groupContestDates: groupContestDates,
	getUserProfile: getUserProfile,
	createUserPublicProfile: createUserPublicProfile,
	updateUserProfile: updateUserProfile,
	submitJoinRequest: submitJoinRequest,
}

async function format(response) {
	console.log(response);
	if (response.status === 404 || response.status === 500) {
		return {
			error: 'Error Loading',
			status: response.status, 
		}
	} else if (response.status === 401) {
		return {
			error: 'Unauthorized',
			status: response.status, 
		}
	} else {
		return response.json()
	}
}

function handleError(error) {
	console.log(error)
}

function userHistory(day, week, year) {
	let url = single + '/users/userstats/history'

	// day = 3
	// week = 10
	// year = 2020

	return Services.get(url + '/' + day + '/' + week + '/' + year)
		.then(format)
		.catch(handleError)
}

function groupHistory(start, end) {
	let url = group + '/groups/user/scores'
	let date = {
		startdate: start,
		enddate: end,
	}

	return Services.get(url + '?date=' + JSON.stringify(date))
		.then(format)
		.catch(handleError)
}

function groupStats(start, end) {
	let url = group + '/groups/group/scores'
	let date = {
		startdate: start,
		enddate: end,
	}

	return Services.get(url + '?date=' + JSON.stringify(date))
		.then(format)
		.catch(handleError)
}

function groupHistoryWeek(start, end) {
	let url = group + '/groups/user/scores'

	let date = {
		startdate: start,
		enddate: end,
	}

	return Services.get(url + '?date=' + JSON.stringify(date))
		.then(format)
		.catch(handleError)
}

function singleUserDaily(start, end) {

	let url = group + '/groups/user/scores/daily'
	let date = {
		startdate: start,
		enddate: end,
	}
	return Services.get(url + '?date=' + JSON.stringify(date))
		.then(format)
		.catch(handleError)
}

//start date and end date doubt?
function teamRank(start, end, rankParam, rankOrder) {
	let url = group + '/groups/teams/scores'

	let date = {
		startdate: start,
		enddate: end,
	}

	let rank = {
		name: rankParam,
		order: rankOrder,
	}

	let query = queryString.stringifyUrl({url: url, query: {date: JSON.stringify(date), rank: JSON.stringify(rank)}});

	return Services.get(query)
		.then(format)
		.catch(handleError)
}

function usersRank(start, end, rankParam, rankOrder) {
	let url = group + '/groups/users/scores'

	let date = {
		startdate: start,
		enddate: end,
	}
	let rank = {
		name: rankParam,
		order: rankOrder,
	}

	let query = queryString.stringifyUrl({url: url, query: {date: JSON.stringify(date), rank: JSON.stringify(rank)}});

	return Services.get(query)
		.then(format)
		.catch(handleError)
}

function userScores(start, end, rankParam, rankOrder) {
	let url = group + '/groups/user/scores'

	let date = {
		startdate: start,
		enddate: end,
	}
	let rank = {
		name: rankParam,
		order: rankOrder,
	}

	let query = queryString.stringifyUrl({url: url, query: {date: JSON.stringify(date), rank: JSON.stringify(rank)}});

	return Services.get(query)
		.then(format)
		.catch(handleError)
}

function getUserProfile(userID) {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let url = group + '/groups/'+ groupID +'/user/' + userID + '/profile';

	return Services.get(url)
		.then(format)
		.catch(handleError)
}

function createUserPublicProfile(userID, username) {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let url = group + '/groups/'+ groupID +'/user/' + userID + '/profile';
	let profile = {
		groupid: groupID,
		userid: userID,
		publicname: username,
	}

	return Services.post(url, profile)
		.then(format)
		.catch(handleError)
}

function updateUserProfile(userID, profile) {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let url = group + '/groups/'+ groupID +'/user/' + userID + '/profile';

	return Services.put(url, profile)
		.then(format)
		.catch(handleError)
}

function singleTeamStatus(start, end) {
	let url = group + '/groups/team/scores?date='

	let date = {
		startdate: start,
		enddate: end,
	}

	return Services.get(url + JSON.stringify(date))
		.then(format)
		.catch(handleError)
}

function groupMessages() {
	let url = group + '/groups/messages'

	return Services.get(url)
		.then(format)
		.catch(handleError)
}

function groupLogo() {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let url = group + '/groups/'+groupID+'/logo'

	return Services.get(url)
		.then(format)
		.catch(handleError)
}

function groupName() {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let url = group + '/groups/'+groupID+'/name'

	return Services.get(url)
		.then(format)
		.catch(handleError)
}

function groupContestDates() {
	let url = group + '/groups/config/dates'

	return Services.get(url)
		.then(format)
		.catch(handleError)
}

function submitJoinRequest(userid){
	let groupID = process.env.REACT_APP_GROUP_ID;
	let url = group + '/groups/'+groupID+'/join'

	return Services.post(url, {userid: userid})
		.then(format)
		.catch(handleError)
}

export default UserStats
