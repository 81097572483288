import React, { Component } from 'react'
import { Box } from './styled'
import {
    VictoryChart,
    VictoryAxis,
    VictoryArea,
    VictoryLabel,
    VictoryScatter,
    VictoryVoronoiContainer,
    VictoryTooltip,
} from 'victory'
import {
    AreaChartColor2,
    AreaChartColor3,
    AreaChartBorder,
    AreaChartColor1,
} from '../../assets/colors'
import moment from 'moment'
import { ReactComponent as FlashSmall } from '../../assets/images/shock-small.svg'


class StatusArea extends Component {

    constructor(props) {
        super(props)
        let starttime = new Date(props.starttime)
        let endtime = new Date(props.endtime)

        this.state = {
          data: [],
          dataScatter: [],
          starttime: starttime,
          endtime: endtime,
          domain: { x: [new Date(starttime.setHours(0)), new Date(starttime.setHours(24))], y: [0, 85] },
        };
    }

    componentDidMount() {
        this.fillData()
    }

    fillData = () => {
        let { data, dataScatter } = this.state
        let { stats, gestureData } = this.props

        if (stats) {
            let y = 0;
            let cnt = 0;
            for (let i = 0; i < stats.length; i++) {
                cnt = stats[i].hasdata ? cnt+1 : cnt;
                y += stats[i].score;
                if (i%15 === 0) {
                    cnt = (cnt === 0) ? 1 : cnt;
                    y /= cnt;
                    data.push({ x: new Date(stats[i].from), y: y, label: y.toFixed(1)})
                    y=0;
                    cnt=0;
                }
            }
            if ( gestureData ) {
                dataScatter = gestureData.map((i) => { return {x: new Date(i.beginat), y:5, type: 'scatter'}})
            }
            this.setState({
                data,
                dataScatter,
            })
        }
    }

    render() {
        return (
            <Box>
                <svg style={{ height: 0 }}>
                    <defs>
                        <linearGradient
                            id="myGradient"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                        >
                            <stop offset="0%" stopColor={AreaChartColor3} />
                            <stop offset="50%" stopColor={AreaChartColor2} />
                            <stop offset="100%" stopColor={AreaChartColor1} />
                        </linearGradient>
                    </defs>
                </svg>
                <VictoryChart
                    // animate={{
                    //     duration: 500,
                    // }}
                    width={600} height={300} scale={{ x: "time" }}
                    domain={this.state.domain}
                    containerComponent={
                        <VictoryVoronoiContainer
                        />
                        }
                    >
                    <VictoryArea
                        style={{
                            data: {
                                fill: 'url(#myGradient)',
                                stroke: AreaChartBorder,
                                strokeWidth: 1,
                            },
                        }}
                        labelComponent={<VictoryTooltip/>}
                        data={this.state.data}
                        standalone={true}
                        interpolation={'natural'}
                    />
                    <VictoryScatter
                        size={5}
                        dataComponent={<FlashSmall />}
                        data={this.state.dataScatter}
                    />

                    <VictoryAxis
                        tickCount={4}
                        tickLabelComponent={<VictoryLabel />}
                        tickFormat={t => {
                                let d = new Date(t)
                                return moment(d).format("HH:mm")
                            }
                        }
                        offsetY={20}
                        style={{
                            axis: {
                                stroke: 'grey',
                                strokeDasharray: 3,
                            },
                            ticks: {
                                stroke: 'grey',
                                size: -5,
                                fillOpacity: 0.5,
                                padding: -30,
                            },
                            tickLabels: {
                                fontSize: 12,
                                fontWeight: '500',
                                padding: 10,
                            },
                        }}
                    />
                </VictoryChart>

            </Box>
        )
    }
}

export default StatusArea
