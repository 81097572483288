import React, { Component } from 'react';
import * as Services from '../../core/auth/authService';
import Api from '../../core/api';
import moment from 'moment';
import { Box, Content } from './styled';

import TeamCard from '../../components/TeamCard';
import Loader from '../../components/Loader';


class Teams extends Component {

	state = {
		teams: null,
		loading: true,
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData = async () => {
		await Api.groupContestDates().then(resp =>{
			console.log("groupContestDates", resp);
			if (resp && !resp.error) {
				let contestStartDate = moment(resp.data.StartDate).utc().startOf('day').toISOString();
				let contestEndDate = moment(resp.data.EndDate).utc().startOf('day').toISOString();

				Api.teamRank(contestStartDate, contestEndDate, "globalscore", -1).then(resp => {
					if (resp && resp.data && !resp.data.error) {
						this.setState({
							teams: resp.data,
						})
					} else if (resp.status === 401) {
						Services.deleteToken()
						document.location.assign('/auth/login')
					}
					this.setState({ loading: false })
				})

				this.setState({
					contestStartDate,
					contestEndDate,
				})
				}
			});
	}

	render()
	{
		let { teams, loading } = this.state;

		return(
			<Box>
				<Content>
					<Loader visibility={loading} color='grey' />
					{teams && teams.map( team => 
						<TeamCard key={team.teamid} team={team} color={team.color}/>
					)}
				</Content>
			</Box>
		)
	}
}

export default Teams;