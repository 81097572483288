import React, { Component } from 'react'
import {
	Box,
	Cards,
	News,
	Image,
	CardComponent,
	MiddleCard,
	UserInfos,
} from './styled'
import GeneralCard from './GeneralCard'
import StatusCard from './StatusCard'
import StatsTeam from './StatsTeam'
import MessagesList from '../../components/MessagesList'
import CommonCard from '../../components/DashBoardCommon'
import YellowCard from '../../components/DashBoardNew'
import Loader from '../../components/Loader'
import OutlinedSection from '../../components/OutlinedSection';
import {
	BlueCardColor1,
	BlueCardColor2,
	RedCardColor1,
	RedCardColor2,
	YellowCardColor2,
	YellowCardColor1,
	Black,
} from '../../assets/colors'
import BreakingNews from '../../assets/images/BreakingNews.png'
import * as Services from '../../core/auth/authService'
import Api from '../../core/api'
import moment from 'moment'

import { Red, Blue } from '../../assets/colors'

import {
	Grid,
	Divider,
} from '@material-ui/core';


class Dashboard extends Component {
	state = {
		todayUserStats: null,
		contestUserStats: null,
		teamRank: null,
		teamRankLoading: true,
		contestTeamScores: null,
		todayTeamScore: null,
		groupMessages: null,
	}

	componentDidMount() {
		this.checkToken(this.fetchData);
	}

	fetchData = () => {
		let startDate =moment().utc().startOf('day').toISOString();
		let endDate = moment().utc().endOf('day').toISOString();

		Api.groupContestDates().then(resp =>{
			console.log("groupContestDates", resp);
			if (resp && !resp.error) {
				let contestStartDate = moment(resp.data.StartDate).utc().startOf('day').toISOString();
				//let contestEndDate = moment(resp.data.EndDate).utc().startOf('day').toISOString();

				Api.userScores(contestStartDate, endDate, "globalscore", -1).then(resp => {
					console.log(resp);
					if (resp && resp.data && !resp.data.error) {
						this.setState({
							contestUserStats: resp.data,
						})
					}
				});

				Api.userScores(startDate, endDate, "globalscore", -1).then(resp => {
					console.log(resp);
					if (resp && resp.data && !resp.data.error) {
						this.setState({
							todayUserStats: resp.data,
						})
					}
				});

				Api.groupStats(contestStartDate, endDate).then(resp => {
					console.log(resp);
					if (resp && !resp.error) {
						this.setState({
							groupStats: resp.data,
						})
					} else if (resp.status === 401) {
						Services.deleteToken()
						document.location.assign('/auth/login')
					}
				});

				Api.teamRank(contestStartDate, endDate, "globalscore", -1).then(resp => {
					console.log("teamRank", resp.data);
					let arr = []
					if (resp && resp.data && !resp.data.error) {
						for (let i = resp.total, j = 1; i > 0; i--, j++) {
							arr.push({
								rank: j,
								marks: resp.data[i - 1].globalscore.toFixed(0),
								name: resp.data[i - 1].publicname,
							})
						}
						this.setState({
							teamRank: resp.data,
						})
					}
					this.setState({ teamRankLoading: false })
				})

				Api.singleTeamStatus(contestStartDate, endDate).then(data => {
					if (data && data.data && !data.data.error) {
						console.log(data);

						this.setState({
							contestTeamScores: data.data,
						})
					}
				})

				Api.singleTeamStatus(startDate, endDate).then(resp => {
					if (resp && resp.data && !resp.data.error) {
						console.log("singleTeamStatus", resp);
						this.setState({
							todayTeamScore: resp.data,
						})
					}
				})
			}
		});

		Api.groupMessages().then( resp => {
			console.log(resp);
			if (resp && !resp.error) {
				this.setState({
					groupMessages: resp.data,
				})
			} else if (resp.status === 401) {
				Services.deleteToken()
				document.location.assign('/auth/login')
			}
		});
	}

	checkToken = (cb) => {
		let token = Services.getToken()
		if (!token) {
			document.location.assign('/auth/login')
		}
		if (cb) {
			cb()
		}
	}

	render() {
		return (
			<Grid container spacing={3} style={{background: '#fff', margin: '0.5% 2% 1% 2%', borderRadius: '10px',}}>
				<Grid item xs={12} md={9}>
						<OutlinedSection title="Résultats en équipe" color={Red} borderColor={Red}>
							<GeneralCard title="Equipes" rank={this.state.teamRank} loading={this.state.teamRankLoading} />
							<StatsTeam todayTeamScore={this.state.todayTeamScore} contestTeamScores={this.state.contestTeamScores}/>
						</OutlinedSection>

						<OutlinedSection title="Résultats solo" color={Blue} borderColor={Blue}>
							<StatusCard todayUserStats={this.state.todayUserStats} contestUserStats={this.state.contestUserStats} />
							<Grid container spacing={3}>
								<Grid item xs={12} md={4}>
									<CommonCard
										color1={BlueCardColor1}
										color2={BlueCardColor2}
										card={'inactive'}
										value1={this.state.contestUserStats ? Math.round(Number(this.state.contestUserStats.averageinactivitycount)) : 0}
										value2={this.state.groupStats ? Math.round(Number(this.state.groupStats.dailyaverageinactivitycount)) : 0}
										textLeft={'Ma moyenne quotidienne:'}
										textRight={'Moyenne quotidienne concours:'}
										title={'Inactivitées'}
									/>
								</Grid>
								<Grid item xs={12} md={4}>
									<MiddleCard>
										<CommonCard
											color1={RedCardColor1}
											color2={RedCardColor2}
											card={'alert'}
											value1={this.state.contestUserStats ? Math.round(Number(this.state.contestUserStats.averageriskygesturecount)) : 0}
											value2={this.state.groupStats ? Math.round(Number(this.state.groupStats.dailyaverageriskygesturecount)) : 0}
											textLeft={'Ma moyenne quotidienne:'}
											textRight={'Moyenne quotidienne concours:'}
											title={'Gestes à risques'}
										/>
									</MiddleCard>
								</Grid>
								<Grid item xs={12} md={4}>
									<YellowCard
										color1={YellowCardColor1}
										color2={YellowCardColor2}
										text1 = {'Mon utilisation moyenne'}
										value1 = {this.state.contestUserStats ? Math.round(Number(this.state.contestUserStats.averageusetime/3600)) : 0}
										units1 = {'h/j'}
										text2 = {'Utilisation moyenne du jeu'}
										value2 = {this.state.groupStats ? Math.round(Number(this.state.groupStats.dailyaverageusetime/3600))  : 0}
										units2 = {'h/j'}
									/>
								</Grid>
							</Grid>
						</OutlinedSection>
				</Grid>
				<Grid item xs={12} md={3}>
					<News>
						<UserInfos>
							<OutlinedSection title="Mes infos" color={Red} borderColor={Red} titleWidth="100px">
								<div style={{fontWeight: 600}}>
									Pseudo: {this.state.contestUserStats && this.state.contestUserStats.publicname}
								</div>
								<div style={{fontWeight: 600}}>
									Equipe: {this.state.todayTeamScore && this.state.todayTeamScore.publicname}
								</div>
							</OutlinedSection>
						</UserInfos>
						<Image src={BreakingNews} alt="" />

						{!this.state.groupMessages ? (
							<Loader visibility={!this.state.groupMessages} color={Black} />
						) : (
							<MessagesList messages={this.state.groupMessages ? this.state.groupMessages : []} height={'700px'}/>
						)}
					</News>
				</Grid>
			</Grid>
		)
	}
}

export default Dashboard
