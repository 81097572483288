import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent } from '@material-ui/core';

import { ReactComponent as Inactive } from '../../assets/images/ic_journee_inactivite.svg';
import { ReactComponent as Exercise } from '../../assets/images/ic_journee_exercices.svg';
import { ReactComponent as Alerts } from '../../assets/images/ic_journee_alerte.svg';

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '1200px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  member: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#FFFFFF00',
  },
  memberTitle: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#FFFFFF00',
    ontWeight: 500,
  	fontSize: '15pt',
  	marginTop: '30px',
  },
  teamName: {
  	textAlign: 'center',
  	color: 'white',
  	fontWeight: 700,
  	fontSize: '20pt',
  },
  rank: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
    fontWeight: 700,
  	fontSize: '15pt',
  },
  card: {
  	padding: 0,
  	backgroundColor: 'tomato',
  	borderRadius: '20px',
  	margin: '0 20px 20px 20px',
  	//marginLeft: '20px',
  },
  scores: {
  	height: '100%',
  	padding: 0,
  	fontWeight: 500,
  	fontSize: '18pt',

  },
  score: {
  	borderRight: '1px solid white',
  	borderLeft: '1px solid white',
  	borderBottom: '1px solid white ',
  	borderRadius: 0,
  	backgroundColor: '#11ffee00',
  	padding: '10px',
  	margin: 0,
  	color: 'white',
    alignItems:'center',
    textAlign: 'center',
  },
  lastScore: {
  	borderRight: '1px solid white',
  	borderLeft: '1px solid white',
  	borderRadius: 0,
  	backgroundColor: '#11ffee00',
  	padding: '10px',
  	margin: 0,
  	color: 'white',
    alignItems:'center',
    textAlign: 'center',
  },
  members: {
  	padding: 0,
  	fontWeight: 500,
  	fontSize: '18pt',
  },
  icon: {
  	// path: {
  	// 	fill: '#ffffff',
  	// },
  	// stroke: '#ffffff',
  	margin: '0 10px',
  	width: '25px',
  	maxHeight: '30px',
  },
  line1: {
        width: '112px',
        height: '47px',
        borderBottom: '1px solid red',
        transform:`
            translateY(-20px)
            translateX(5px)
            rotate(27deg)`,
        position: 'absolute',
        /* top: -20px; */
    },
});

class TeamCard extends Component {

	ColorLuminance = (hex, lum) => {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, '');
		if (hex.length < 6) {
			hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		var rgb = "#", c, i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i*2,2), 16);
			c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
			rgb += ("00"+c).substr(c.length);
		}
		return rgb;
	}

	render()
	{
		const { classes } = this.props;
		const { team } = this.props;
		const { color } = this.props;
		console.log("color", color);
		console.log("team", team);
		return(
			<div className={classes.root}>
				<Card className={classes.card} style={{ background: 'linear-gradient(to right bottom, '+color+', '+this.ColorLuminance(color, -0.3)+')'}}>
					<CardContent style={{ padding: 0 }}>
						<Grid container spacing={3} alignItems="center">
							<Grid item xs={12} md={8} className={classes.members}>

								<Grid container>
									<Grid item xs={12}>
										<div className={classes.teamName}>Nom : {team.publicname}</div>
									</Grid>
									<Grid item xs={12}>
										<div className={classes.memberTitle}>Membres:</div>
									</Grid>
									<Grid item xs={12}>
										<div className={classes.member}> {team.memberspublicname.join(' | ')} </div>
									</Grid>
								</Grid>

							</Grid>
							<Grid item xs={12} md={2} className={classes.scores}>
								<Grid container alignItems="center">
									<Grid item xs={12}>
										<div className={classes.score}>
											<Inactive className={classes.icon}/>
											{team.inactivitycount}
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className={classes.score}>
											<Alerts className={classes.icon}/>
											{team.riskygesturecount}
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className={classes.score}>
											<Exercise className={classes.icon}/>
											{team.exercisecount}
										</div>
									</Grid>
									<Grid item xs={12}>
										<div className={classes.lastScore}>
											{team.globalscore.toFixed(0)} pts
										</div>
									</Grid>
								</Grid>

							</Grid>
							<Grid item xs={12} md={2}>
								<div className={classes.rank}>Rang: {team.rank}</div>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</div>
		)
	}
}

export default withStyles(styles)(TeamCard);