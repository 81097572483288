import styled from 'styled-components'

export const Main = styled.div`
    height: ${props => props.size + 'px'};
    width: ${props => props.size + 'px'};
    border-radius: ${props => props.size / 2 + 'px'};
    background: ${props => props.background};
    color: ${props => props.color};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

export const Points = styled.div`
    font-weight: bold;
    font-size: ${props => props.font + 'px'};
`

export const Label = styled.div`
    font-weight: bold;
    font-size: ${props =>
        props.small ? props.font - 10 + 'px' : props.font + 'px'};
`
