import React, { Component } from 'react'
import {
	Content,
	Box,
} from './styled';

import { withRouter } from "react-router-dom";

class Invite extends Component {

	componentDidMount() {
		const id = this.props.match.params.id;
		console.log("invite id:", id);
		this.setState({
			inviteid: id,
		});
	}


	render() {
		return (
			<Box>
				<Content>
					<div>
						Join from invite
					</div>
				</Content>
			</Box>
		);
	}
}

export default withRouter(Invite);