import styled from 'styled-components'

import { Red, White } from '../../assets/colors'


export const Oulined = styled.div`
	border: 2px solid ${props => props.borderColor ? props.borderColor : Red};
	border-radius: 20px;
	margin-top: 20px;
	margin-bottom: 10px;
`

export const TitleContainer = styled.div`
	text-align: center;
    margin-top: -10px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
`

export const Title = styled.div`
	background-color: ${White};
	color: ${props => props.color ? props.color : Red};
	max-width: ${props => props.titleWidth ? props.titleWidth : '200px'};
	margin-right: auto;
	margin-left: auto;
`

export const Content = styled.div`
	padding: 10px;
`