import styled from 'styled-components'
import { Shadow } from '../../assets/colors'

export const Box = styled.div``

export const Line = styled.div`
    height: 8px;
    border-radius: 10px;
    transition: width 0.6s ease-in-out 0.3s;
    background: ${props => props.color && props.color};
    box-shadow: ${props => props.shadow && ' 0px 0.5px 3px 0px ' + Shadow};
`
