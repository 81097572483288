import styled from 'styled-components'
import { Red, White } from '../../../assets/colors'

export const CardDiv = styled.div`
    background: ${Red};
    border-radius: 10px;
    padding: 10px;
    display: flex;
    color: ${White};
    min-height: 200px;
`

export const CenterItems = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
`

export const Head = styled.div`
    border: 1px solid white;
    border-radius: 15px;
    padding: 5px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    height: 20px;
    width: 150px;
    font-size: 20px;
`

export const Footer = styled.div`
    margin-top: 40px;
`

export const Table = styled.div`
    margin-top: 10px;
    border-bottom: 1px solid white;
    display: flex;
    padding: 10px 0px;
    width: 100%;
`

export const Label = styled.div`
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    padding: 0 5px;
    display: flex;
    justify-content: ${props =>
        props.center ? 'center' : props.end ? 'flex-end' : 'flex:start'};
`

export const LeftTrophy = styled.div`
    flex: 1;
`

export const RightTrophy = styled.div`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`
