import React, { Component } from 'react'
import Message from '../Message'
import { MessageContainer } from './styled'

export default class MessagesList extends Component {
  render() {
    let {messages} = this.props;
    return (
      <MessageContainer className="message-list">
        {messages.map(message => {
          return (
           <Message key={message.id} message={message}/>
         )
       })}
     </MessageContainer>
    )
  }
}