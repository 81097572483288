import styled from 'styled-components'
import { White, Red } from '../../../assets/colors'

export const Box = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${Red};
`

export const Logo = styled.div`
    padding-top: 40px;
    padding-bottom: 30px;
`

export const InputBox = styled.div`
    display: flex;
    flex-direction: column;
`

export const ConnectDiv = styled.div`
    padding: 20px 0;
`

export const ForgetPasswordDiv = styled.div`
    color: ${White};
`

export const SignUpDiv = styled.div`
    padding-top: 100px;
    padding-bottom: 50px;
`
