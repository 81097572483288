import React, { Component } from 'react'
import * as Services from '../../core/auth/authService';
import Api from '../../core/api';

import { TextField, Button, Grid } from '@material-ui/core';

import { withSnackbar } from 'notistack';

import {
	Box,
	Content,
} from './styled';

class Profile extends Component {

	state={
		groupProfile: {},
	}

	fetchData = () => {
		let userID = Services.getUserIdFromToken();
		Api.getUserProfile(userID).then((resp) => {
			console.log(resp);
			if (resp.data) {
				this.setState({
					groupProfile: resp.data,
				})
			}
		})
	}

	handleUsername = (username) => {
		let groupProfile = this.state.groupProfile;
		groupProfile.publicname = username;
		this.setState({
			groupProfile: groupProfile,
		});
	}

	updateGroupProfile = () => {
		let userID = Services.getUserIdFromToken();
		let groupProfile = this.state.groupProfile;
		Api.updateUserProfile(userID, groupProfile).then((resp) => {
			console.log(resp);
			this.props.enqueueSnackbar('Profil mis à jour.', { variant: 'success'});
		})
	}

	componentDidMount() {
		this.fetchData();
	}

	render() {
		return(
			<Box>
				<Content>
					
					<div>
						Profil public
					</div>
					<Grid container spacing={3} alignItems="center" alignContent="center" justify="center">
						<Grid item>
							<TextField
								id="outlined-basic"
								label="Pseudo"
								variant="outlined"
								placeholder='Pseudo'
								value={this.state.groupProfile.publicname ? this.state.groupProfile.publicname  : ''}
								onChange={(e) => this.handleUsername(e.target.value)}
							/>
						</Grid>
						<Grid item>
							<Button 
								variant="contained" 
								color="primary"
								onClick={this.updateGroupProfile}
							>
								Enregister
							</Button>
						</Grid>
					</Grid>
				</Content>
			</Box>
		);
	}
}

export default withSnackbar(Profile);