import React, { Component } from 'react'
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';

import { withStyles } from '@material-ui/core/styles';
import { UnitsInputContainer } from './styled.js'

const UnitsOutlinedInput = withStyles({
	root: {
		borderRadius: '50px',
		borderColor: 'white',
		backgroundColor: 'white',
		width: '85px', 
		height: '35px',
		'& fieldset ': {
			border: 'none',
		},
	},
})(OutlinedInput);

export default class WeightInput extends Component {

	handleChange = (event) => {
		this.props.onChange(event);
	}

	render () {
		let { units, label } = this.props
		return(
		<UnitsInputContainer>
			<UnitsOutlinedInput
			    id={"outlined-adornment-" + label}
			    onChange={this.handleChange}
			    endAdornment={<InputAdornment position="end">{units}</InputAdornment>}
			    inputProps={{
			      'aria-label': {label},
			    }}
			    labelWidth={0}
			 />
		</UnitsInputContainer>
	)}
}