import styled from 'styled-components'
import { Red, profileBack, White } from '../../assets/colors'
import { NavLink } from 'react-router-dom'

export const Div = styled.div`
    display: ${props => (props.logged ? 'flex' : 'none')};
    flex-direction: column;
`

export const Head = styled.div`
    background: ${Red};
    display: flex;
`

export const Logo = styled.div`
    flex: 1;
`

export const Profile = styled.div`
    display: flex;
    /*padding: 20px;*/
    justify-content: center;
    align-items: center;
    background: ${profileBack};
    margin: 15px;
    font-size: 25px;
    color: ${Red};
    font-weight: bold;
    border-radius: 25px;
    cursor: pointer;
    user-select: none;
`

export const Organisation = styled.div`
    align-items: center;
    text-align: center;
    color: ${White};
    font-size: 35px;
    font-weight: bold;
`

export const Menu = styled.div`
    display: ${props => !props.display && 'none'};
    position: absolute;
    user-select: none;
    top: 80px;
    right: 10px;
    width: 120px;
    z-index: 2;
    background: ${Red};
    padding: 0 5px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
    > ul {
        list-style: none;
        padding: 5px;
        margin: 0;
        background: ${Red};
        > li {
            padding: 2px;
            color: ${White};
            border-bottom: solid white 1px;
            padding: 5px;
            :hover {
                cursor: pointer;
                background-color: #ec7d73;
            }
            :last-child {
                border-bottom: none;
            }
        }
    }
`

export const MenuItems = styled.div`
    display: flex;
    min-height: 40px;
    justify-content: center;
    background: ${White};
    padding: 0 100px;
    padding-top: 10px;
    @media screen and (max-width: 1500px) {
        height: 40px;
    }
    /* margin-bottom: 5px; */
    margin-right: auto;
    margin-left: auto;
`

export const Link = styled.div`
    font-size: 20px;
    /* font-style: italic; */
    margin: 0 20px;
    /* margin-bottom: 5px; */
`

export const StyledNAvLink = styled(NavLink)`
    color: ${Red};
    text-decoration: none;
    display: flex;
    width: fit-content;
    &.active {
        > div {
            border-bottom: 5px solid ${Red};
        }
    }
    &:hover {
        > div {
            border-bottom: 5px dotted ${Red};
        }
    }
`
