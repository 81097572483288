import styled from 'styled-components'
import { AppBack } from '../../assets/colors'

export const MessageComponent = styled.div`
	margin: 20px;
`

export const MessageDate = styled.div`
	font-size: 10px;
`
export const MessageAuthor = styled.div`
	font-size: 10px;
`
export const MessageContent = styled.div`
	padding: 15px 20px;
    background: ${AppBack};
    font-size: 20px;
    font-weight: 200;
    border-radius: 10px;
`