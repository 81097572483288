import React, { Component } from 'react'
import {
	Box,
	Logo,
	// Body,
	// Left,
	// VerticalLine,
	// Right,
	MinHead,
	InformationDiv,
	ItemDiv,
	Label,
	ButtonDiv,
	MultipleButtons,
	DropDownBox,
	DropDownLabel,
	// DropDownMenu,
	SubmitDiv,
	// Main,
	Perco,
} from './styled'
import './style.css'
import Button from '../../../components/Button'
import { Red, White } from '../../../assets/colors'
import Input from '../../../components/Input'
import UnitsInput from '../../../components/UnitsInput'
import ValidationMessage from '../../../components/ValidationMessage'

import Api from '../../../core/api'
import { ReactSVG } from 'react-svg'

import Percko from '../../../assets/images/logo_pk_pro.png'
import LoaderClass from '../../../components/Loader'
import * as Services from '../../../core/auth/authService'
import { DatePickerClass } from '../../../components/datePicker'
//import { registerLocale } from "react-datepicker";
import { fr } from "date-fns/locale";

import ReactHtmlParser from 'react-html-parser'; 

import Grid from '@material-ui/core/Grid';

export class Register extends Component {

	componentDidMount() {
		this.fetchData()
	}

	fetchData = async () => {
		await Api.groupLogo().then(data => {
			if (data && data.data && !data.data.error) {
				console.log(data.data.url);
				this.setState({
					logo: data.data.url,
				})
			}
		})
	}

	state = {
		loading: false,
		gender: 'female',
		laterality: 'right-handed',
		sport: 1,
		height: undefined,
		heightValid: false,
		weight: undefined,
		weightValid: false,
		birthdate: null,
		birthdateValid: false,
		publicname: '',
		username: '', 
		usernameValid: false,
		email: '', 
		emailValid: false,
		password: '', 
		passwordValid: false,
		passwordConfirm: '', 
		passwordConfirmValid: false,
		formValid: false,
		errorMsg: {}
	}

	validateForm = () => {
		const {birthdateValid, weightValid, heightValid, usernameValid, emailValid, passwordValid, passwordConfirmValid} = this.state;
		this.setState({
			formValid: birthdateValid && weightValid && heightValid && usernameValid && emailValid && passwordValid && passwordConfirmValid
		})
	}

	handleGender = (event) => {
		this.setState({
			gender: event.target.value,
		})
	}

	handleLaterality = (event) => {
		this.setState({
			laterality: event.target.value,
		})
	}

	handleSport = (event) => {
		this.setState({
			sport: parseInt(event.target.value),
		})
	}

	updateHeight = height => {
		this.setState({height}, this.validateHeight)
	}

	validateHeight = () => {
		const {height} = this.state;
		let heightValid = true;
		let errorMsg = {...this.state.errorMsg};

		let heightInt = parseInt(height);
		if (isNaN(heightInt)) {
			heightValid = false;
			errorMsg.height = 'Valeur de taille non valide';
		} else if (heightInt <= 0) {
			heightValid = false;
			errorMsg.height = 'Valeur de taille non valide';
		}

		this.setState({heightValid, errorMsg}, this.validateForm);
	}

	updateWeight = weight => {
		this.setState({weight}, this.validateWeight);
	}

	validateWeight = () => {
		const {weight} = this.state;
		let weightValid = true;
		let errorMsg = {...this.state.errorMsg};

		let weightInt = parseInt(weight);
		if (isNaN(weightInt)) {
			weightValid = false;
			errorMsg.weight = 'Valeur de poids non valide';
		} else if (weightInt <= 0) {
			weightValid = false;
			errorMsg.weight = 'Valeur de poids non valide';
		}

		this.setState({weightValid, errorMsg}, this.validateForm);
	}

	updateUsername = username => {
		this.setState({username}, this.validateUsername);
	}

	validateUsername = () => {
		const {username} = this.state;
		let usernameValid = true;
		let errorMsg = {...this.state.errorMsg};

		if (username.length < 3) {
			usernameValid = false;
			errorMsg.username = 'Le pseudo doit comporter plus de 3 caractères.';
		}

		this.setState({usernameValid, errorMsg}, this.validateForm);
	}

	updateEmail = (email) => {
		this.setState({email}, this.validateEmail);
	}

	validateEmail = () => {
		const {email} = this.state;
		let emailValid = true;
		let errorMsg = {...this.state.errorMsg};

		// checks for format _@_._
		if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
			emailValid = false;
			errorMsg.email = 'Invalid email format';
		}

		this.setState({emailValid, errorMsg}, this.validateForm);
	}

	updatePassword = (password) => {
		this.setState({password}, this.validatePassword);
	}

	validatePassword = () => {
		const {password} = this.state;
		let passwordValid = true;
		let errorMsg = {...this.state.errorMsg};

		// must be 8 chars
		// and 3 of the following:
		// must contain a number
		// must contain a special character
		// must contain lowercase
		// must contain uppercase
		let pattern1 = /^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*)$/
		let pattern2 = /^(.{0,7}|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$/
		let pattern3 = /^(.{0,7}|[^0-9]*|[^a-z]*|[a-zA-Z0-9]*)$/
		let pattern4 = /^(.{0,7}|[^0-9]*|[^A-Z]*|[a-zA-Z0-9]*)$/


		if (password.length < 8) {
			passwordValid = false;
			errorMsg.password = 'Votre mot de passe doit contenir au moins 8 caractères.';
		} else if (pattern1.test(password) && pattern2.test(password) && pattern3.test(password) && pattern4.test(password) ){
			passwordValid = false;
			errorMsg.password = 'Votre mot de passe doit contenir une combinaison d\'au moins 3 des éléments suivants: majuscule, minuscule, chiffre, symbole';
		}

		this.setState({passwordValid, errorMsg}, this.validateForm);
	}

	updatePasswordConfirm = (passwordConfirm) => {
		this.setState({passwordConfirm}, this.validatePasswordConfirm);
	}

	validatePasswordConfirm = () => {
		const {passwordConfirm, password} = this.state;
		let passwordConfirmValid = true;
		let errorMsg = {...this.state.errorMsg};

		if (password !== passwordConfirm) {
			passwordConfirmValid = false;
			errorMsg.passwordConfirm = 'Les mots de passes de correspondent pas.';
		}

		this.setState({passwordConfirmValid, errorMsg}, this.validateForm);
	}

	updateBirthdate = birthdate => {
		this.setState({birthdate}, this.validateBirthdate);
	}

	validateBirthdate = () => {
		const {birthdate} = this.state;
		let birthdateValid = true;
		let errorMsg = {...this.state.errorMsg};

		if (!birthdate) {
			birthdateValid = false;
			errorMsg.birthdate = 'Veuillez entrer votre date de naissance.';
		}

		this.setState({birthdateValid, errorMsg}, this.validateForm);
	}

	handleSubmit = () => {
		let information = {
				'groupid': process.env.REACT_APP_GROUP_ID,
				'registration': {
					'email': this.state.email,
					'password': this.state.password,
				},
				'profile': {
					'timezone': new Date().getTimezoneOffset(),
					'lang': 'fr',
					'birthdate': this.state.birthdate,
					'height': parseInt(this.state.height),
					'weight': parseInt(this.state.weight),
					'isathletic': this.state.sport,
					'islefthanded': this.state.laterality === 'left-handed' ? true : false,
					'gender':  this.state.gender,
				},
				'public': {
					'groupid': process.env.REACT_APP_GROUP_ID,
					'publicname':  this.state.username,
				},
		}

		Services.register(information).then(response => {
			if (response.error) {
				response.data.then((data) => {
					let errorMsg = {...this.state.errorMsg};

					console.log(data);
					let error = data.error;
					switch(error) {
						case 'ErrInvalidBodyParameters':
							errorMsg.submit = 'Erreur dans le formulaire';
						break;
						case 'ErrEmailAddressAlreadyUsed':
							errorMsg.submit = ReactHtmlParser('Cette adresse email est déjà utilisée, vous pouvez vous connecter en cliquant sur <a href="/auth/login">ce lien</a>');
						break;
						default:
							errorMsg.submit = 'Une erreur inattendue est survenue.';
						break;
					}

					this.setState({'formValid': false});
					this.setState({'submitValid': false});
					this.setState({'errorMsg': errorMsg});
				});

			}
			else {
				console.log("Successful registration");
				//this.props.history.push('/auth/login')
			}
		})
	}

	render() {
		let { gender, laterality, sport } = this.state
		return (
			<Box> 
					<Grid container spacing={0}>
					<Grid item xs={12}>
					<Logo>
						<ReactSVG
							src={this.state.logo}
							wrapper="span"
							className="logo-small"
						/>
					</Logo>
					</Grid>

						<Grid item xs={0} md={1}>
						</Grid>
						<Grid item xs={12} md={5}>

									<MinHead>Mon profil Wavii</MinHead>
									<InformationDiv>
										<ItemDiv>
											<Label>Sexe:</Label>
											<MultipleButtons>
												<ButtonDiv>
													<Button
														onClick={this.handleGender}
														backgroundColor={
															gender==='female' ? White : Red
														}
														shadow={
															gender==='female' &&
															'0 4px 4px 0 rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.19)'
														}
														textColor={gender==='female' ? Red : White}
														fontSize={18}
														padding={'10px 20px'}
														borderRadius={30}
														borderColor={White}
														value='female'
													>
														Femme
													</Button>
												</ButtonDiv>
												<ButtonDiv>
													<Button
														onClick={this.handleGender}
														backgroundColor={
															gender==='male' ? White : Red
														}
														shadow={
															gender==='male' &&
															'0 4px 4px 0 rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.19)'
														}
														textColor={gender==='male' ? Red : White}
														fontSize={18}
														padding={'10px 20px'}
														borderRadius={30}
														borderColor={White}
														value='male'
													>
														Homme
													</Button>
												</ButtonDiv>
											</MultipleButtons>
										</ItemDiv>
										<ItemDiv>
											<Label>Latéralité :</Label>
											<MultipleButtons>
												<ButtonDiv>
													<Button
														onClick={this.handleLaterality}
														backgroundColor={
															laterality==='right-handed' ? White : Red
														}
														textColor={
															laterality==='right-handed' ? Red : White
														}
														shadow={
															laterality==='right-handed' &&
															'0 4px 4px 0 rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.19)'
														}
														fontSize={18}
														padding={'10px 20px'}
														borderRadius={30}
														borderColor={White}
														value='right-handed'
													>
														Droitier
													</Button>
												</ButtonDiv>
												<ButtonDiv>
													<Button
														onClick={this.handleLaterality}
														backgroundColor={
															laterality==='left-handed' ? White : Red
														}
														textColor={
															laterality==='left-handed' ? Red : White
														}
														shadow={
															laterality==='left-handed' &&
															'0 4px 4px 0 rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.19)'
														}
														fontSize={18}
														padding={'10px 20px'}
														borderRadius={30}
														borderColor={White}
														value="left-handed"
													>
														Gaucher
													</Button>
												</ButtonDiv>
											</MultipleButtons>
										</ItemDiv>
										<ItemDiv>
											<Label>Pratique sportive :</Label>
											<MultipleButtons>
												<ButtonDiv>
													<Button
														onClick={this.handleSport}
														backgroundColor={
															sport <= 5 ? White : Red
														}
														textColor={
															sport <= 5 ? Red : White
														}
														shadow={
															sport <= 5 &&
															'0 4px 4px 0 rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.19)'
														}
														fontSize={18}
														padding={'10px 20px'}
														borderRadius={30}
														borderColor={White}
														value={1}
													>
														Ponctuellement
													</Button>
												</ButtonDiv>
												<ButtonDiv>
													<Button
														onClick={this.handleSport}
														backgroundColor={
															sport > 5 ? White : Red
														}
														textColor={
															sport > 5 ? Red : White
														}
														shadow={
															sport > 5 &&
															'0 4px 4px 0 rgba(0, 0, 0, 0.2),0 6px 10px 0 rgba(0, 0, 0, 0.19)'
														}
														fontSize={18}
														padding={'10px 20px'}
														borderRadius={30}
														borderColor={White}
														value={6}
													>
														Régulièrement
													</Button>
												</ButtonDiv>
											</MultipleButtons>
										</ItemDiv>
										<ItemDiv>
											<Label>Date de naissance</Label>
											<DatePickerClass
												locale={ fr }
												selected={ this.state.birthdate }
												onChange={ this.updateBirthdate }
											/>
											<ValidationMessage valid={this.state.birthdateValid} message={this.state.errorMsg.birthdate} />
										</ItemDiv>
										<DropDownBox>
											<DropDownLabel>Taille</DropDownLabel>
											<UnitsInput 
												onChange={(e) => this.updateHeight(e.target.value)}
												units="cm"
												label="height"
											 />
											 <ValidationMessage valid={this.state.heightValid} message={this.state.errorMsg.height} />
										</DropDownBox>
										<DropDownBox>
											<DropDownLabel>Poids</DropDownLabel>
											<UnitsInput
											onChange={(e) => this.updateWeight(e.target.value)}
												units="kg"
												label="weight"
											/>
											<ValidationMessage valid={this.state.weightValid} message={this.state.errorMsg.weight} />
										</DropDownBox>
										
									</InformationDiv>
							</Grid>
							<Grid item xs={12} md={5}>
								<MinHead>Informations du compte</MinHead>
								<InformationDiv>
									<ItemDiv>
										<Label>Prénom ou pseudo :</Label>
										<Input
											type="text"
											width={'300px'}
											border={'none'}
											outline={'none'}
											radius={'30px'}
											padding={'15px 30px'}
											size={'18px'}
											margin={'0 10px'}
											onChange={(e) => this.updateUsername(e.target.value)}
										/>
										<ValidationMessage valid={this.state.usernameValid} message={this.state.errorMsg.username} />
									</ItemDiv>
									<ItemDiv>
										<Label>Adresse email :</Label>
										<Input
											type="email"
											width={'300px'}
											border={'none'}
											outline={'none'}
											radius={'30px'}
											padding={'15px 30px'}
											size={'18px'}
											margin={'0 10px'}
											onChange={(e) => this.updateEmail(e.target.value)}
										/>
										<ValidationMessage valid={this.state.emailValid} message={this.state.errorMsg.email} />
									</ItemDiv>
									<ItemDiv>
										<Label>Mot de passe :</Label>
										<Input
											type="password"
											width={'300px'}
											border={'none'}
											radius={'30px'}
											outline={'none'}
											padding={'15px 30px'}
											size={'18px'}
											margin={'0 10px'}
											onChange={(e) => this.updatePassword(e.target.value)}
										/>
										<ValidationMessage valid={this.state.passwordValid} message={this.state.errorMsg.password} />
									</ItemDiv>
									<ItemDiv>
										<Label>
											Confirmer votre mot de passe :
										</Label>
										<Input
											type="password"
											width={'300px'}
											radius={'30px'}
											border={'none'}
											outline={'none'}
											padding={'15px 30px'}
											size={'18px'}
											margin={'0 10px'}
											onChange={(e) => this.updatePasswordConfirm(e.target.value)}
										/>
										<ValidationMessage valid={this.state.passwordConfirmValid} message={this.state.errorMsg.passwordConfirm} />
									</ItemDiv>
								</InformationDiv>
							</Grid>
							<Grid item xs={0} md={1}>
							</Grid>
					<Grid item xs={12}>
					<LoaderClass
						visibility={this.state.loading}
						color={White}
						signup={1}
					/>
					<SubmitDiv>
						<Button
							onClick={this.handleSubmit}
							backgroundColor={Red}
							textColor={White}
							fontSize={30}
							padding={'18px 100px'}
							borderRadius={40}
							borderColor={White}
							disabled={!this.state.formValid}
						>
							Valider
						</Button>
						<ValidationMessage valid={this.state.submitValid} message={this.state.errorMsg.submit} />
					</SubmitDiv>
				  </Grid>
				  <Grid item xs={12}>
				<Perco>
					<img src={Percko} alt={''} />
				</Perco>
				</Grid>
				</Grid>
			</Box>
		)
	}
}


export default Register
