import styled from 'styled-components'
import { White } from '../../assets/colors'

export const Box = styled.div`
    margin: 0.5% 2% 1% 2%;
    flex: 1;
    max-width: 1356px;
`

export const Content = styled.div`
    background: ${White};
    padding: 20px;
    margin: 0 20%;
    border-radius: 10px;
    font-size: 15px;
    justify-content: center;
    text-align: center;
`