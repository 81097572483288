import urlService from '../urlServices.js';
import * as Services from '../fetchServices';
const md5 = require('js-md5');

function setToken(data) {
	console.log("setToken", data);
	if (data) {
		return localStorage.setItem('token', JSON.stringify(data));
	}
	return false;
}

function parseJwt(token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}

export function getToken() {
	let storedToken = localStorage.getItem('token');
	let token ;
	try {
		token = JSON.parse(storedToken);
	}
	catch(e) {
		console.log(e);
		deleteToken();
	}
	console.log(token);
	return token;
}

export function getGroupIdFromToken(){
	let token = getToken();
	return parseJwt(token).groupid;
}

export function getUserIdFromToken(){
	let token = getToken();
	console.log("getUserIdFromToken", token);
	return parseJwt(token).userid;
}

export function setUserEmail(data) {
	if (data) {
		return localStorage.setItem('md5', JSON.stringify(data));
	}
	return false;
}

export function getUserEmail(data) {
	let storedEmail = localStorage.getItem('md5');
	let email ;
	try {
		email = JSON.parse(storedEmail);
	}
	catch(e) {
		console.log(e);
		localStorage.removeItem('md5')
	}
	return email;
}

export function deleteToken() {
	console.log("deleteToken");
	return localStorage.removeItem('token')
}

async function format(response) {
	console.log(response);
	return response.json()
	if (response.status === 404 || response.status === 500) {
		return {
			error: 'Not Found',
			status: response.status,
			data: response.json()
		}
	} else if (response.status === 401) {
		return {
			error: 'Unauthorized',
			status: response.status,
			data: response.json()
		}
	}
	else if (response.status === 400) {
		return {
			error: 'Bad Request',
			status: response.status,
			data: response.json()
		}
	}
	else if (response.status !== 200 && response.status !== 201) {
		return {
			error: 'Unknown error',
			status: response.status,
			data: response.json()
		}
	} else {
		return response.json()
	}
}

function save(data) {
	console.log(data);
	if (!data.error) {
		var token = data.data.token;
		if (token) {
			setToken(token);
			setUserEmail(md5(data.data.email));
		}
		return data.data
	} else {
		return data
	}
}

export async function login(email, pass) {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let service = urlService.auth
	var url = service + '/group/auth/login'

	return fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin' : '*',
		},
		body: JSON.stringify({
			data: {
				email: email,
				password: pass,
				groupid: groupID,
			},
		}),
	})
		.then(format)
		.then(save)
}

export async function register(body) {
	let service = urlService.auth
	var url = service + '/group/auth/register'
	return Services.post(url, {
		data: body,
	}).then(format)
}

export async function logOut() {
	// let service = urlService.auth
	// var url = service + '/userAuth/logout'
	return deleteToken()
}

export function requestPasswordResetLink(email) {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let service = urlService.auth
	let url = service + '/group/' + groupID + '/auth/forgotPassword'
	let body = {
		data: {
			email: email,
		}
	}

	return Services.post(url, body)
		.then(format)
}


export function passwordReset(password, email, token) {
	let groupID = process.env.REACT_APP_GROUP_ID;
	let service = urlService.auth
	let url = service + '/group/' + groupID + '/auth/forgotPassword/' + token;
	let body = {
		data: {
			email: email,
			password: password,
		}
	}

	return Services.post(url, body)
		.then(format)
}
