import React, { Component } from 'react'
import {
	Logo,
	Box,
	MinHead,
	InformationDiv,
	Label,
	ItemDiv,
	SubmitDiv,
} from './styled';
import { Red, White } from '../../../assets/colors';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ValidationMessage from '../../../components/ValidationMessage';
import { withRouter } from "react-router-dom";
import Api from '../../../core/api';

import { Grid } from '@material-ui/core';
import { ReactSVG } from 'react-svg';



class Join extends Component {

	state={
		logo: null,
		groupName: null,
		userid: null,
	}

	componentDidMount() {
		this.fetchData();

		const id = this.props.match.params.id;
		console.log("user id:", id);
		this.setState({
			userid: id,
		});
	}

	fetchData = () => {
		Api.groupLogo().then(data => {
			if (data && data.data && !data.data.error) {
				this.setState({
					logo: data.data.url,
				})
			}
		});

		Api.groupName().then(data => {
			if (data && data.data && !data.data.error) {
				this.setState({
					groupName: data.data,
				})
			}
		})
	}

	submitJoinRequest = () => {
		Api.submitJoinRequest(this.state.userid)
			.then((resp)=>{
				console.log(resp);
		});
	}

	render() {
		return (
			<Box>
				<Grid 
					container
					spacing={3}
					alignItems="center"
					alignContent="center"
					justify="center"
				>
					<Grid item xs={12}>
						<Logo>
							<ReactSVG
								src={this.state.logo}
								wrapper="span"
								className="logo-small"
							/>
						</Logo>
					</Grid>

					<Grid item >
						<div style={{textAlign: "center"}}>
							Vous n'êtes pas encore membre du groupe {this.state.groupName}
						</div>
						<div style={{textAlign: "center"}}>
							Pour rejoindre le groupe, vous pouvez demander à rejoindre le groupe en cliquant sur le bouton ci-dessous.
						</div>
						<SubmitDiv>
							<Button
								onClick={this.submitJoinRequest}
								backgroundColor={Red}
								textColor={White}
								fontSize={20}
								padding={'10px 50px'}
								borderRadius={40}
								borderColor={White}
							>
								Rejoindre
							</Button>
					</SubmitDiv>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

export default withRouter(Join);